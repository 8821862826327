/*
  arg: { is_hoge: "example" }
  return { isHoge: "example" }
*/

export const toCamelCaseObjectFromSnakeCase =<T extends object>(obj: T): T => {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      if (typeof key !== 'string') {
        return key
      }
      if (Object.prototype.toString.call(value) === '[object Array]') {
        return [key.replace(/_./g, (str) => {
          return str.charAt(1).toUpperCase()
        }), value.map((v: any) => ( // eslint-disable-line
          typeof v === 'object' && Object.prototype.toString.call(v) !== '[object Array]'
            ? toCamelCaseObjectFromSnakeCase(v)
            : v
        ))]
      }
      return [key.replace(/_./g, (str) => {
        return str.charAt(1).toUpperCase()
      }), !value ? value
          : Object.prototype.toString.call(value) === '[object Array]'
            ? value.map((v: any) => toCamelCaseObjectFromSnakeCase(v)) // eslint-disable-line
            : typeof value === 'object' ? toCamelCaseObjectFromSnakeCase(value) : value
      ]
    })
  ) as T
}