import React from 'react'
import { Row, Container, Col } from 'react-bootstrap'

import { TopStepper } from '../components/Nav/TopStepper'

import { SideStepperLayoutPropsType } from './types'

export const TopStepperLayout: React.FC<SideStepperLayoutPropsType> = (props) => {
  return (
    <Container fluid='lg' className='top-nav-layout'>
      {
        !!props.title &&
        <Row className='mt-sm-5 mt-3 mx-sm-3 mx-lg-5'>
          <Col>
            <h3 className='text-nowrap'>{props.title}</h3>
          </Col>
          {
            !!props.customMenu &&
            <Col>{props.customMenu}</Col>
          }
        </Row>
      }
      <Row className='my-2 mx-sm-3 mx-lg-5'>
        <Col xs={12}>
          <TopStepper
            {...props}
          />
        </Col>
      </Row>
      <Row className='mb-3 py-2 mx-sm-3 mx-lg-5 p-sm-3 p-md-4 px-lg-5 shadow-sm bg-white rounded main-card'>
        <Col xs={12} className='h-100 overflow-scroll position-relative'>
          {props.children}
        </Col>
      </Row>
    </Container>
  )
}