import React from 'react'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import { DocumentFieldsFormPropsType } from '../../types'

import { DocumentFieldForm } from '../DocumentFieldForm'

export const DocumentFieldsForm: React.FC<DocumentFieldsFormPropsType> = (props) => {
  const contractInviteeOptions = props.contractInvitees.map(invitee=>({value: invitee.id, label: `${invitee.inviteeLastName}${invitee.inviteeFirstName}`}))

  return (
    <>
      {props.documentFields.map((field, index) => 
        field.documentId === props.currentDocumentPage?.id ?
        <DocumentFieldForm
          {...props}
          key={field.key}
          index={index}
          documentField={field}
          removeDocumentField={()=>props.removeDocumentField(index)}
          contractInviteeOptions={contractInviteeOptions}
          isNoLocation={!props.fieldLocations?.some(loc => loc.fieldKey === field.key)}
        /> : <React.Fragment key={field.key}></React.Fragment>
      )}
      <Card className="my-2">
        <Button
          variant='outline-primary'
          disabled={props.isDisabled}
          onClick={()=> !props.isDisabled && props.currentDocumentPage?.id && props.appendDocumentField(props.currentDocumentPage.id, undefined, props.currentDocumentPage.page)}
        >
          <h6 className="my-0 text-center"><FontAwesomeIcon icon={faPlus}/> 記入欄を追加</h6>
        </Button>
      </Card>
    </>
  )
}