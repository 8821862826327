import React from 'react'
import { useForm } from 'react-hook-form'
import { Fetch } from '@/utils/Fetch'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

import { DocumentsTabDisplay } from '@/components/features/Document/components'
import { OutputLocationType } from '@/components/features/ItemOutputLocation/types'

import { ConfirmationPropsType } from '../../types'
import { convertInputTypeToAnswerType } from '../../hooks/useCustomerContractForm'

export const Confirmation: React.FC<ConfirmationPropsType> = (props) => {
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm()
  const submitContract = handleSubmit(async () => {
    const res = await Fetch(`/api/v1/customer_contracts/${props.customerContract?.id}`, 'PATCH', {customer_contract: {status: 'pending'}})
    if (res.ok) {
      props.refreshCustomerContract()
    } else {
      const json = await res.json()
      const errorMessage = json.errors?.join('\n')
      alert(errorMessage || '送信に失敗しました')
    }
  })

  const answerOutputLocations: OutputLocationType[] = []
  Object.keys(props.customerContract?.answerItems).forEach(questionItemId=> {
    const questionItem = props.contractSetting?.questionItems.find(question => question.id === questionItemId)
    if (!questionItem) return

    const answerItem = props.customerContract?.answerItems[questionItemId]
    if (!answerItem.value) return

    const itemLocations: OutputLocationType[] = questionItem.outputLocations?.map(loc=>{
      const originalDocument = props.contractSetting?.draftDocuments.find(doc=>doc.id === loc.documentId)
      const document = props.customerContract.documents.find(doc=>doc.blobId === originalDocument?.blobId)
      return {
        ...loc,
        label: questionItem.label,
        documentId: document?.id || loc.documentId,
        sublabel: questionItem.sublabel,
        value: answerItem.value,
        subvalue: answerItem.subvalue,
        unit: questionItem.unit,
        answerType: questionItem.answerType,
        itemKey: questionItem.id,
        width: questionItem?.width,
        height: questionItem?.height
      }
    }) || []
    answerOutputLocations.push(...itemLocations)
  })
  const fieldOutputLocations: OutputLocationType[] = props.customerContract?.contractInvitees.flatMap(invitee => {
    const inviteeFields = invitee.documentFields.flatMap(field => {
      const fieldLocations = field.fieldLocations.map(loc => ({
        ...loc,
        itemKey: field.id,
        label: `${invitee.inviteeLastName}${invitee.inviteeFirstName}`,
        documentId: field.documentId,
        answerType: convertInputTypeToAnswerType(field.inputType)
      }))
      return fieldLocations
    })
    return inviteeFields
  }) || []
  const fixedOutputLocations = [...answerOutputLocations, ...fieldOutputLocations]

  return (
    <>
    <header className="row align-items-center justify-content-end sticky-top bg-white z-1500">
      <Col className='h4 mb-0 text-truncate'>{props.customerContract?.name}</Col>
      <Col className='text-end'>
        <Button
          variant="success"
          onClick={submitContract}
          disabled={props.customerContract?.status !== 'draft' || isSubmitting}
        >
          送信{props.customerContract?.status !== 'draft' && '済み'}{isSubmitting && '中'}
        </Button>
      </Col>
    </header>
    <Row>
      <Col className=''>
        <Row className='gy-2 mt-3 mx-0 border-bottom'>
          <Col lg={2} md={3}>承認ユーザー</Col>
          <Col lg={10} md={9}>
            {
              props.customerContract?.contractInvitees.filter(invitee=>invitee.inviteeType === 'User').map(approver =>
                <span key={approver.inviteeId} className='me-3'>
                  {approver.inviteeLastName}{approver.inviteeFirstName}
                </span>
              )
            }
          </Col>
        </Row>
        <DocumentsTabDisplay
          documents={props.customerContract?.documents || []}
          fixedOutputLocations={fixedOutputLocations}
          tabsClassName='pt-5'
        />
      </Col>
    </Row>
    </>
  )
}