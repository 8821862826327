import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'

import { DocumentPaginationPropsType } from '../../types'

export const DocumentPagination: React.FC<DocumentPaginationPropsType> = (props) => {
  return (
    <div className="text-end my-2">
      <span className='mx-2'>{props.currentPage}/{props.totalPages}</span>
      <ButtonGroup>
        <Button
          variant="outline-dark"
          size="sm"
          className="px-3"
          disabled={props.currentPage <= 1}
          onClick={()=> props.setCurrentPage(props.currentPage > 1 ? props.currentPage - 1 : 1)}
        >
          <FontAwesomeIcon icon={faChevronLeft}/>
        </Button>
        <Button
          variant="outline-dark"
          size="sm"
          className="px-3"
          disabled={!props.totalPages || props.currentPage >= props.totalPages}
          onClick={()=> props.setCurrentPage(!props.totalPages ? 1 : (props.currentPage < props.totalPages ? props.currentPage + 1 : props.totalPages))}
        >
          <FontAwesomeIcon icon={faChevronRight}/>
        </Button>
      </ButtonGroup>
    </div>
  )
}