import React, { useEffect } from 'react'
import Nav from 'react-bootstrap/Nav'
import Button from 'react-bootstrap/Button'

import { TopStepperPropsType } from './types'

export const TopStepper: React.FC<TopStepperPropsType> = (props) => {
  const setCurrentStepByHash = () => {
    if (props.setCurrentStep) {
      const currentStep = location.hash.replace('#', '')
      if (!currentStep) return
      props.setCurrentStep(prevSteps => prevSteps.map(step=>({...step, isCurrent: step.value === currentStep})))
      props.afterStepChange && props.afterStepChange(currentStep)
    }
  }
  const shownSteps = props.steps.filter(step=>!step.isSkipped)

  useEffect(() => {
    setCurrentStepByHash()
    window.addEventListener('hashchange', setCurrentStepByHash)
  }, [])

  return (
    <Nav className="top-bar row position-relative">
      {
        shownSteps.map((step, index) =>
          <Nav.Item
            key={step.value}
            className='d-flex flex-column col position-relative'
          >
            {
              index !== shownSteps.length - 1 &&
              <div className={`position-absolute step-hol-connect ${step.isDone ? 'border-primary' : 'border-secondary'}`}></div>
            }
            <span className={`border rounded-circle mx-1 mb-2 status-circle z-100 ${step.isDone ? 'bg-primary' : 'bg-light' }`}>
            </span>
            {
              step.isCurrent ?
              <span className='text-primary'>{step.label}</span>
              :
              <Button
                variant='link'
                className='p-0 text-black-50 text-start'
                disabled={props.setCurrentStep === undefined || step.isDisabled}
                href={`#${step.value}`}
              >
                {step.label}
              </Button>
            }
          </Nav.Item>
        )
      }
    </Nav>
  )
}