import React from 'react'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'

import { ContainerLayoutPropsType } from './types'

export const ContainerLayout: React.FC<ContainerLayoutPropsType> = (props) => {
  return (
    <Container>
      <Row>
        {props.children}
      </Row>
    </Container>
  )
}