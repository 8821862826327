import React from 'react'
import { Controller } from 'react-hook-form'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Select from 'react-select'

import { DocumentsTabDisplay } from '@/components/features/Document/components'

import { PreviewsPropsType, statusOptions } from '../../types'

export const Previews: React.FC<PreviewsPropsType> = (props) => {
  const outputLocations = props.contractSetting?.questionItems.flatMap(item => {
    const itemLocations = item.outputLocations?.map(loc => ({
      itemKey: item.id,
      label: item.label,
      sublabel: item.sublabel,
      unit: item.unit,
      customerOrder: item.customerOrder,
      answerType: item.answerType,
      width: item?.width,
      height: item?.height,
      ...loc
    })) || []
    return itemLocations
  })

  return (
    <>
    <header className="row align-items-center justify-content-end sticky-top bg-white z-1500">
      <Form onSubmit={props.submitContractSetting}>
        <Col className='d-flex justify-content-end'>
          <Controller
            control={props.contractSettingControl}
            name={'statusOption'}
            render={({ field }) => (
              <Select
                options={statusOptions}
                {...field}
              />
            )}
          />
          <Button
            variant="primary"
            className="ms-3"
            onClick={props.submitContractSetting}
            disabled={!props.isContractSettingFormDirty || props.isSubmittingContractSetting}
          >
            保存{!props.isContractSettingFormDirty && '済み'}{props.isSubmittingContractSetting && '中'}
          </Button>
          {
            !!props.contractSetting?.id &&
            <Button
              variant="danger"
              className="ms-3"
              onClick={props.deleteContractSetting}
            >
              削除
            </Button>
          }
        </Col>
      </Form>
    </header>
    <DocumentsTabDisplay
      documents={props.contractSetting?.draftDocuments || []}
      outputLocations={outputLocations}
      tabsClassName='pt-5'
    />
    </>
  )
}