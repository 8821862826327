import React from 'react'
import { Stage, Layer, Text, Group, Rect, Circle, Ellipse } from 'react-konva'
import { KonvaEventObject } from 'konva/lib/Node'
import dayjs from 'dayjs'

import { ItemOutputLocationsStagePropsType, OutputLocationType } from '../../types'

const dashArray = (width: number, height: number): number[] => {
  return [width + height - 20, ...[...Array(Math.ceil(width / 8) + 2)].flatMap(() => [3, 5]), 3]
}

export const ItemOutputLocationsStage: React.FC<ItemOutputLocationsStagePropsType> = (props) => {
  const handleDragEnd = (e: KonvaEventObject<DragEvent>, i: number) => {
    props.setLocationsValue && props.setLocationsValue(i, Math.round(e.target.x() / (props.pageScale || 1)), Math.round(e.target.y() / (props.pageScale || 1)))
  }

  const handleDelete = (i: number) => {
    if (props.removeLocation) {
      props.removeLocation(i)
    }
  }

  const switchPlaceholder = (location: OutputLocationType) => {
    switch (location.answerType) {
      case 'signature_field':
        return `${location.label}の署名`
      case 'signature_date_field':
        return dayjs(new Date()).format('YYYY年MM月DD日 | --:--')
      case 'checkbox_field':
        return '✔️'
      case 'stamp_field':
        return `${location.label}\nの押印`
      case 'text_field':
      case 'textarea_field':
        return `${location.label}の直接記入欄`
      case 'date':
        return `${location.value ? dayjs(location.value).format('YYYY年MM月DD日') : location.label}`
      default:
        return (!!location.value || location.value === 0) ? `${location.value}${location.subvalue || ''}` : `${location.label}${location.sublabel || ''}`
    }
  }

  const switchFontSize = (location: OutputLocationType) => {
    switch (location.answerType) {
      case 'signature_field':
        return 13
      case 'stamp_field':
        return 11
      default:
        return 10
    }
  }

  const switchWidth = (location: OutputLocationType) => {
    switch (location.answerType) {
      case 'textarea_field':
        return 360
      case 'signature_field':
      case 'signature_date_field':
        return 120
      case 'stamp_field':
        return 60
      case 'checkbox_field':
        return 12
      default:
        return 180
    }
  }

  const switchHeight = (location: OutputLocationType) => {
    switch (location.answerType) {
      case 'textarea_field':
        return 120
      case 'stamp_field':
        return 60
      case 'checkbox_field':
        return 12
      default:
        return 24
    }
  }

  const switchAlign = (location: OutputLocationType) => {
    switch (location.answerType) {
      case 'signature_field':
      case 'checkbox_field':
      case 'stamp_field':
        return 'center'
      default:
        return 'left'
    }
  }

  const switchVerticalAlign = (location: OutputLocationType) => {
    switch (location.answerType) {
      case 'checkbox_field':
      case 'stamp_field':
        return 'middle'
      default:
        return 'top'
    }
  }

  return (
    <Stage
      width={props.width || 0}
      height={props.height || 0}
      style={{
        position: 'absolute',
        bottom: 0
      }}
    >
      <Layer>
        {
          props.outputLocations.map((loc, i) =>
            loc.documentId === props.currentDocumentPage?.id &&
            loc.page === props.currentDocumentPage?.currentPage &&
            <Group
              key={i}
              x={loc.xCoord * props.pageScale}
              y={loc.yCoord * props.pageScale}
              draggable={!!props.setLocationsValue}
              onDragEnd={e => handleDragEnd(e, i)}
            >
              <Text
                text={switchPlaceholder(loc)}
                fill={props.setLocationsValue ? '#adb5bd' : '#495057'}
                width={(loc.width || switchWidth(loc)) * props.pageScale}
                height={(loc.height || switchHeight(loc)) * props.pageScale}
                fontSize={switchFontSize(loc) * props.pageScale}
                align={switchAlign(loc)}
                verticalAlign={switchVerticalAlign(loc)}
                padding={7 * props.pageScale * (loc.answerType === 'checkbox_field' ? 0 : 1)}
              />
              {
                (props.setLocationsValue || (!loc.value && loc.value !== 0)) &&
                <Rect
                  width={(loc.width && loc.width > 0 ? loc.width : switchWidth(loc)) * props.pageScale}
                  height={(loc.height && loc.height > 0 ? (loc.answerType === 'textarea' ? 90 : loc.height) : switchHeight(loc)) * props.pageScale}
                  cornerRadius={3 * props.pageScale}
                  stroke={props.setLocationsValue ? '#ffaa66' : '#adb5bd'}
                  dash={
                    loc.answerType === 'textarea' ?
                    dashArray((loc.width && loc.width > 0 ? loc.width : switchWidth(loc)) * props.pageScale, (loc.height && loc.height > 0 ? (loc.answerType === 'textarea' ? 90 : loc.height) : switchHeight(loc)) * props.pageScale)
                    : undefined
                  }
                />
              }
              {
                loc.answerType === 'checkbox_field' &&
                <Text
                  text={`${loc.label}${loc.sublabel || ''}`}
                  fill='#adb5bd'
                  fontSize={8 * props.pageScale}
                  height={12 * props.pageScale}
                  width={100 * props.pageScale}
                  offsetY={10 * props.pageScale}
                />
              }
              {
                loc.answerType === 'stamp_field' && loc.width && loc.width > 4 && loc.height && loc.height > 4 &&
                <Ellipse
                  radiusX={(loc.width / 2 - 2) * props.pageScale}
                  radiusY={(loc.height / 2 - 2) * props.pageScale}
                  stroke='#adb5bd'
                  strokeWidth={props.pageScale}
                  offsetX={-(loc.width / 2) * props.pageScale}
                  offsetY={-(loc.height / 2) * props.pageScale}
                />
              }
              {
                !!props.removeLocation &&
                <Group
                  onClick={() => handleDelete(i)}
                >
                  <Circle
                    radius={6 * props.pageScale * (loc.answerType === 'checkbox_field' ? 0.6 : 1)}
                    fill='#f8f9fa'
                    stroke='#adb5bd'
                    strokeWidth={0.5 * props.pageScale}
                  />
                  <Text
                    text='ー'
                    fontStyle='bold'
                    fill='#adb5bd'
                    width={10 * props.pageScale * (loc.answerType === 'checkbox_field' ? 0.6 : 1)}
                    height={10 * props.pageScale * (loc.answerType === 'checkbox_field' ? 0.6 : 1)}
                    fontSize={8 * props.pageScale * (loc.answerType === 'checkbox_field' ? 0.6 : 1)}
                    offsetX={5 * props.pageScale * (loc.answerType === 'checkbox_field' ? 0.6 : 1)}
                    offsetY={4 * props.pageScale * (loc.answerType === 'checkbox_field' ? 0.6 : 1)}
                    align='center'
                    verticalAlign='middle'
                  />
                </Group>
              }
            </Group>
          )
        }
        {
          props.fixedOutputLocations?.map((loc, i) =>
            loc.documentId === props.currentDocumentPage?.id &&
            loc.page === props.currentDocumentPage?.currentPage &&
            <Group
              key={i}
              x={loc.xCoord * props.pageScale}
              y={loc.yCoord * props.pageScale}
            >
              <Text
                text={switchPlaceholder(loc)}
                fill='#495057'
                width={(loc.width || switchWidth(loc)) * props.pageScale}
                fontSize={switchFontSize(loc) * props.pageScale}
                align={switchAlign(loc)}
                verticalAlign={switchVerticalAlign(loc)}
                padding={7 * props.pageScale * (loc.answerType === 'checkbox_field' ? 0 : 1)}
              />
              {
                !loc.value && loc.value !== 0 &&
                <Rect
                  width={(loc.width && loc.width > 0 ? loc.width : switchWidth(loc)) * props.pageScale}
                  height={(loc.height && loc.height > 0 ? loc.height : switchHeight(loc)) * props.pageScale}
                  cornerRadius={3 * props.pageScale}
                  stroke='#adb5bd'
                />
              }
              {
                loc.answerType === 'checkbox_field' &&
                <Text
                  text={(!!loc.value || loc.value === 0) ? `${loc.value}${loc.subvalue || ''}` : `${loc.label}${loc.sublabel || ''}`}
                  fill='#adb5bd'
                  fontSize={8 * props.pageScale}
                  height={12 * props.pageScale}
                  width={100 * props.pageScale}
                  offsetY={10 * props.pageScale}
                />
              }
              {
                loc.answerType === 'stamp_field' && loc.width && loc.width > 4 && loc.height && loc.height > 4 &&
                <Ellipse
                  radiusX={(loc.width / 2 - 2) * props.pageScale}
                  radiusY={(loc.height / 2 - 2) * props.pageScale}
                  stroke='#adb5bd'
                  strokeWidth={props.pageScale}
                  offsetX={-(loc.width / 2) * props.pageScale}
                  offsetY={-(loc.height / 2) * props.pageScale}
                />
              }
            </Group>
          )
        }
      </Layer>
    </Stage>
  )
}