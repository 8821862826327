import React from 'react'
import Form from 'react-bootstrap/Form'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Select from 'react-select'
import { Controller } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan, faShare, faCheck } from '@fortawesome/free-solid-svg-icons'

import { DocumentFieldFormPropsType, inputTypeOptions } from '../../types'

export const DocumentFieldForm: React.FC<DocumentFieldFormPropsType> = (props) => {
  return (
    <Card className="p-2 my-2">
      <Row className='gy-1'>
        <Form.Label column xs={3} lg={4} className="text-nowrap" htmlFor={`inviteeFields.${props.index}.contractInviteeOption`}>記入者</Form.Label>
        <Col xs={9} lg={8} className='ps-0'>
          <Controller
            control={props.customerContractControl}
            name={`documentFields.${props.index}.contractInviteeOption`}
            render={({ field }) => (
              <Select
                options={props.contractInviteeOptions}
                isDisabled={props.isDisabled}
                {...field}
              />
            )}
            rules={{required: true}}
          />
        </Col>
        <Form.Label column xs={3} lg={4} className="text-nowrap" htmlFor={`inviteeFields.${props.index}.inputTypeOption`}>タイプ</Form.Label>
        <Col xs={9} lg={8} className='ps-0'>
          <Controller
            control={props.customerContractControl}
            name={`documentFields.${props.index}.inputTypeOption`}
            render={({ field }) => (
              <Select
                options={inputTypeOptions}
                isDisabled={props.isDisabled}
                {...field}
                onChange={(value)=>{
                  field.onChange(value)
                  if (['signature', 'signature_date', 'stamp'].includes(value?.value || '')) {
                    props.setCustomerContractValue(`documentFields.${props.index}.isRequired`, true)
                  }
                }}
              />
            )}
            rules={{required: true}}
          />
        </Col>
        {
          ['signature', 'signature_date', 'stamp'].includes(props.documentField.inputTypeOption.value) ?
          <>
            <Col xs={3} lg={4} className="text-nowrap">必須</Col>
            <Col xs={1} className='ps-0'>
              <FontAwesomeIcon icon={faCheck} className={props.documentField.isRequired ? 'text-success' : 'text-secondary'}/>
            </Col>
          </>
          :
          <>
            <Form.Label column xs={3} lg={4} className="text-nowrap m-0" htmlFor={`inviteeFields.${props.index}.isRequired`}>必須</Form.Label>
            <Col xs={1} className='my-auto ps-0'>
              <Form.Check
                id={`inviteeFields.${props.index}.isRequired`}
                type="checkbox"
                {...props.customerContractRegister(`documentFields.${props.index}.isRequired`)}
                disabled={props.isDisabled}
              />
            </Col>
          </>
        }
        <Col xs={8} lg={7} className='text-end'>
          <Button
            variant='outline-dark me-1'
            size='sm'
            className='border-0'
            disabled={props.isDisabled}
            onClick={() => !props.isDisabled && props.removeDocumentField && props.removeDocumentField()}
          >
            <FontAwesomeIcon icon={faTrashCan}/>
          </Button>
        </Col>
        {
          props.appendFieldLocation && props.currentDocumentPage?.id && props.currentDocumentPage.id !== 'children' &&
          <Col xs={12} className='text-end'>
            <Button
              variant='outline-primary'
              size='sm'
              disabled={props.isDisabled}
              onClick={() => !props.isDisabled && props.appendFieldLocation && props.currentDocumentPage &&
                props.appendFieldLocation({
                  fieldKey: props.documentField.key,
                  page: props.currentDocumentPage.page,
                  xCoord: 0,
                  yCoord: 0
                })
              }
            >
              PDFに反映
              <FontAwesomeIcon icon={faShare} className='ms-1'/>
            </Button>
            {
              !props.isDisabled && props.isNoLocation &&
              <div className='small text-danger'>※ PDFに反映してください</div>
            }
          </Col>
        }
        {/* {
          props.appendFieldLocation && props.documents &&
          props.inviteeField.fieldLocations?.map((loc, i) =>
            <Col xs={12} key={i} className='small'>
              <span className='ms-1 d-inline-block text-truncate'>P.{loc.page}, X: {loc.xCoord}, Y: {loc.yCoord}</span>
            </Col>
          )
        } */}
      </Row>
    </Card>
  )
}