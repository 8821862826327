import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faAnglesLeft } from '@fortawesome/free-solid-svg-icons'
import Nav from 'react-bootstrap/Nav'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

import { SideStepperPropsType } from './types'

export const SideStepper: React.FC<SideStepperPropsType> = (props) => {
  const setCurrentStepByHash = () => {
    if (props.setCurrentStep) {
      const currentStep = location.hash.replace('#', '')
      if (!currentStep) return
      props.setCurrentStep(prevSteps => prevSteps.map(step=>({...step, isCurrent: step.value === currentStep})))
      props.afterStepChange && props.afterStepChange(currentStep)
    }
  }

  useEffect(() => {
    setCurrentStepByHash()
    window.addEventListener('hashchange', setCurrentStepByHash)
  }, [])

  return (
    <Col xs={12} className="bg-light sidebar border-end">
      <Nav className="flex-column">
        {
          !!props.backPath &&
          <Nav.Item className='my-2'>
            <div className='d-grid'>
              <Button
                variant='outline-dark'
                href={props.backPath}
                size='sm'
              >
                <FontAwesomeIcon icon={faAnglesLeft}/> {props.backTitle || '戻る'}
              </Button>
            </div>
          </Nav.Item>
        }
        {
          props.steps.filter(step=>!step.isSkipped).map(step =>
            <Nav.Item
              key={step.value}
              className='my-2 py-1 d-flex align-items-center'
            >
              <span className="border rounded-circle me-3 bg-light status-circle">
                <FontAwesomeIcon icon={faCheck} className={step.isDone ? 'text-success' : 'text-secondary'}/>
              </span>
              {
                step.isCurrent ?
                <span className='text-dark border-bottom border-dark'>{step.label}</span>
                :
                <Button
                  variant='link'
                  className='p-0'
                  disabled={props.setCurrentStep === undefined || step.isDisabled}
                  href={`#${step.value}`}
                >
                  {step.label}
                </Button>
              }
            </Nav.Item>
          )
        }
      </Nav>
    </Col>
  )
}