import { QuestionItemType, QuestionItemFormType, answerTypeOptions } from '../QuestionItem/types'
import { ItemOutputLocationFormType } from '../ItemOutputLocation/types'
import { DocumentType } from '../Document/types'
import { UseFormRegister, Control, UseFieldArrayAppend, UseFieldArrayRemove, UseFormSetValue } from 'react-hook-form'

export type FilesUploadPropsType = {
  contractSetting?: {
    readonly id: string
    name: string
    draftDocuments: DocumentType[]
    status: (typeof statusOptions)[number]['value']
  }
  contractSettingRegister: UseFormRegister<ContractSettingFormType>
  contractSettingControl: Control<ContractSettingFormType>
  submitContractSetting: (e?: React.BaseSyntheticEvent | undefined) => Promise<void>
  isContractSettingFormDirty: boolean
  isSubmittingContractSetting: boolean
  refreshContractSetting?: () => void
  deleteContractSetting?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export type QuestionItemsSettingPropsType = {
  contractSetting: ContractSettingType
  contractSettingRegister: UseFormRegister<ContractSettingFormType>
  contractSettingControl: Control<ContractSettingFormType>
  submitContractSetting: (e?: React.BaseSyntheticEvent | undefined) => Promise<void>
  appendQuestionItem: (answerTypeOption?: (typeof answerTypeOptions)[number]) => string
  removeQuestionItem: (index?: number | number[] | undefined) => void
  switchQuestionItems: (index: number, arrow: 'up' | 'down') => void
  isContractSettingFormDirty: boolean
  isSubmittingContractSetting: boolean
  appendItemOutputLocation: UseFieldArrayAppend<ContractSettingFormType, 'itemOutputLocations'>
  removeItemOutputLocation: UseFieldArrayRemove
  itemOutputLocations: ItemOutputLocationFormType[]
  setContractSettingValue: UseFormSetValue<ContractSettingFormType>
  refreshContractSetting?: () => void
  deleteContractSetting?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export type InputFieldsSettingPropsType = Omit<QuestionItemsSettingPropsType, 'switchQuestionItems'>

export type PreviewsPropsType = {
  contractSetting?: {
    readonly id: string
    name: string
    status: (typeof statusOptions)[number]['value']
    draftDocuments: DocumentType[]
    questionItems: QuestionItemType[]
  }
  contractSettingControl: Control<ContractSettingFormType>
  submitContractSetting: (e?: React.BaseSyntheticEvent | undefined) => Promise<void>
  isContractSettingFormDirty: boolean
  isSubmittingContractSetting: boolean
  deleteContractSetting?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export type ContractSettingType = {
  readonly id: string
  name: string
  status: typeof statusOptions[number]['value']
  folder?: {
    id: string
    name: string
  }
  customerInviteeNum: number
  draftDocuments: DocumentType[]
  questionItems: QuestionItemType[]
  approverSettings: ApproverSettingType[]
}

type ApproverSettingType = {
  id: string
  userId: string
  lastName: string
  firstName: string
}

export const statusOptions = [
  {value: 'draft', label: '下書き'},
  {value: 'active', label: '有効'},
] as const

export type ContractSettingFormType = {
  name: string
  statusOption: typeof statusOptions[number]
  folderOption?: {
    value: string
    label: string
  }
  customerInviteeNum: number
  additionalDraftDocuments: File[]
  defaultApprovers: {
    value: string
    label: string
  }[]
  questionItems: QuestionItemFormType[]
  itemOutputLocations: ItemOutputLocationFormType[]
}

export type ContractSettingSubmitBodyType = FormData | {
  contract_setting: {
    name?: string
    customer_invitee_num?: number
    status?: typeof statusOptions[number]['value']
    folder_id?: string
    approver_setting_forms?: string[]
    question_item_forms?: {
      id: string
      label: string
      sublabel?: string
      answer_type: string
      unit?: string
      select_items_text?: string
      order: number
      customer_order?: number | null
      approver_setting_id?: string | null
      is_required: boolean
      width?: number
      height?: number
      item_output_location_forms?: {
        draft_document_id: string
        page: number
        x_coord: number
        y_coord: number
      }[]
    }[]
  }
}