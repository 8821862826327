import React from 'react'
import Row from 'react-bootstrap/Row'

import { PlainLayoutPropsType } from './types'

export const PlainLayout: React.FC<PlainLayoutPropsType> = (props) => {
  return (
    <Row className={`ui-layout w-100 m-0 ${props.className}`}>
      {props.children}
    </Row>
  )
}