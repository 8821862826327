import React, { useState, useEffect } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import { useWindowSize } from 'react-use'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { Controller } from 'react-hook-form'
import Select from 'react-select'
import { Fetch } from '@/utils/Fetch'

import { DocumentFieldFormsPropsType } from '../../types'
import { toCamelCaseObjectFromSnakeCase } from '@/utils/toCamelCaseObjectFromSnakeCase'

import { OutputLocationType } from '@/components/features/ItemOutputLocation/types'
import { isFieldTypeQuestion } from '@/components/features/ContractSetting/hooks/useContractSettingForm'
import { convertInputTypeToAnswerType, useCustomerContractForm } from '../../hooks/useCustomerContractForm'
import { DocumentsTabDisplay } from '@/components/features/Document/components'
import { DocumentFieldsForm } from '@/components/features/DocumentField/components/DocumentFieldsForm'

type Options = {
  value: string
  label: string
}[]

type Users = {
  id: string
  lastName: string
  firstName: string
}[]

export const DocumentFieldForms: React.FC<DocumentFieldFormsPropsType> = (props) => {
  const { width } = useWindowSize()
  const [currentDocumentPage, setCurrentDocumentPage] = useState({id: '', page: 1})
  const [show, setShow] = useState(false)
  const [userOptions, setUserOptions] = useState<Options>([])
  const {
    customerContractControl: approversControl,
    submitCustomerContract: submitApprovers,
    isCustomerContractFormDirty: isApproversFormDirty,
    isSubmittingCustomerContract: isSubmittingApprovers
  } = useCustomerContractForm(props.customerContract.id)
  const submitModalFormHandler = async () => {
    await submitApprovers()
    props.refreshCustomerContract && props.refreshCustomerContract()
    setShow(false)
  }
  const fixedOutputLocations: OutputLocationType[] = props.contractSetting?.questionItems.flatMap(item => {
    if (isFieldTypeQuestion(item.answerType)) return []
    const answerItem = props.customerContract?.answerItems[item.id]
    const itemLocations = item.outputLocations?.map(loc => {
      const originalDocument = props.contractSetting?.draftDocuments.find(doc=>doc.id === loc.documentId)
      const document = props.customerContract.documents.find(doc=>doc.blobId === originalDocument?.blobId)
      return {
        ...loc,
        documentId: document?.id || loc.documentId,
        itemKey: item.id,
        label: item.label,
        sublabel: item.sublabel,
        unit: item.unit,
        customerOrder: item.customerOrder,
        answerType: item.answerType,
        value: answerItem?.value,
        subvalue: answerItem?.subvalue,
        width: item?.width,
        height: item?.height
      }
    }) || []
    return itemLocations
  }) || []
  const outputLocations: OutputLocationType[] = props.fieldLocations.map(loc => {
    const documentField = props.documentFields.find(field => field.key === loc.fieldKey) || props.documentFields[0]
    const contractInvitee = props.customerContract.contractInvitees.find(invitee => invitee.id === documentField?.contractInviteeOption.value) || props.customerContract.contractInvitees[0]
    return ({
      ...loc,
      itemKey: loc.fieldKey,
      label: `${contractInvitee?.inviteeLastName}${contractInvitee?.inviteeFirstName}`,
      documentId: documentField?.documentId,
      answerType: convertInputTypeToAnswerType(documentField?.inputTypeOption.value),
      width: documentField.width,
      height: documentField.height
    })
  })
  const setLocationsValue = (fieldIndex: number, xCoord: number, yCoord: number) => {
    props.setCustomerContractValue(`fieldLocations.${fieldIndex}.xCoord`, xCoord, { shouldDirty: true })
    props.setCustomerContractValue(`fieldLocations.${fieldIndex}.yCoord`, yCoord, { shouldDirty: true })
  }

  const getUserOptions = async() => {
    const res = await Fetch('/api/v1/users', 'GET', {})
    const json = await res.json()
    const users = toCamelCaseObjectFromSnakeCase<{users: Users}>(json).users
    setUserOptions(users.map(user => ({value: user.id, label: `${user.lastName}${user.firstName}`})))
  }

  useEffect(() => {
    getUserOptions()
  }, [])

  return (
    <>
      <header className="row align-items-center justify-content-end position-absolute w-100 z-100">
        <Col className='h4 mb-0 text-truncate'>{props.customerContract?.name}</Col>
        <Col className='text-end'>
          <Button
            variant="primary"
            onClick={props.submitCustomerContract}
            disabled={!props.isCustomerContractFormDirty || props.isSubmittingCustomerContract}
          >
            保存{!props.isCustomerContractFormDirty && '済み'}{props.isSubmittingCustomerContract && '中'}
          </Button>
        </Col>
      </header>
      <Row className='h-100 pt-5 position-relative'>
        <div className='pb-4 w-100 position-absolute start-0 bottom-0 z-100 bg-white-to-b'></div>
        <Col md={12} lg={5} xl={4} xxl={3} className={`overflow-scroll h-100 ${width >= 992 ? 'pb-50vh' : 'pb-3'}`}>
          <h5 className='sticky-top bg-white px-2 mb-4 bg-b-4-white'>記入項目</h5>
          <Card className="bg-pink p-2 mb-2 border-0">
            <Row>
              <Col xs={3} className="text-nowrap py-1 text-primary">承認ユーザー</Col>
              <Col xs={9} className="text-end">
                <Button
                  variant='outline-primary'
                  size='sm'
                  onClick={()=>setShow(true)}
                  disabled={props.customerContract.status !== 'draft'}
                >
                  <FontAwesomeIcon icon={faPenToSquare} className='ms-1'/>承認者変更
                </Button>
              </Col>
              <Col xs={12} className="py-1">
                <div className='pb-1 border-bottom border-primary'>
                  {
                    props.customerContract?.contractInvitees.filter(invitee=>invitee.inviteeType === 'User').map(approver =>
                      <span key={approver.inviteeId} className='text-nowrap me-3'>
                        {approver.inviteeLastName}{approver.inviteeFirstName}
                      </span>
                    )
                  }
                </div>
              </Col>
              <Col xs={12} className="text-nowrap py-1 text-primary">クライアント</Col>
              <Col xs={12} className="text-nowrap">
                {
                  props.customerContract?.contractInvitees.filter(invitee=>invitee.inviteeType === 'Customer').map(customer =>
                    <span key={customer.inviteeId} className='text-nowrap me-3'>
                      {customer.inviteeLastName}{customer.inviteeFirstName}
                    </span>
                  )
                }
              </Col>
            </Row>
          </Card>
          <div className='my-2 px-2'><div className='w-100 border-top'></div></div>
          <DocumentFieldsForm
            {...props}
            contractInvitees={props.customerContract.contractInvitees}
            documents={props.customerContract.documents}
            currentDocumentPage={currentDocumentPage}
            isDisabled={props.customerContract.status !== 'draft'}
          />
          {
            width < 992 &&
            <DocumentsTabDisplay
              documents={props.customerContract?.documents || []}
              setCurrentDocumentPage={setCurrentDocumentPage}
              outputLocations={props.customerContract.status === 'draft' ? outputLocations : []}
              fixedOutputLocations={props.customerContract.status === 'draft' ? fixedOutputLocations : [...outputLocations, ...fixedOutputLocations]}
              setLocationsValue={setLocationsValue}
              removeLocation={props.removeFieldLocation}
            />
          }
        </Col>
        {
          width >= 992 &&
          <Col lg={7} xl={8} xxl={9} className='bg-white overflow-scroll h-100 pb-4'>
            <DocumentsTabDisplay
              documents={props.customerContract?.documents || []}
              setCurrentDocumentPage={setCurrentDocumentPage}
              outputLocations={props.customerContract.status === 'draft' ? outputLocations : []}
              fixedOutputLocations={props.customerContract.status === 'draft' ? fixedOutputLocations : [...outputLocations, ...fixedOutputLocations]}
              setLocationsValue={setLocationsValue}
              removeLocation={props.removeFieldLocation}
            />
          </Col>
        }
      </Row>
      <Modal show={show} onHide={()=>setShow(false)}>
        <Modal.Header closeButton>
          承認ユーザーの設定
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={submitModalFormHandler}>
            <Row className='gy-2'>
              <Form.Label column xs={3} htmlFor='approvers'>
                承認ユーザー
              </Form.Label>
              <Col
                xs={9}
              >
                <Controller
                  control={approversControl}
                  name={'approvers'}
                  render={({ field }) => (
                    <Select
                      options={userOptions}
                      isMulti
                      isDisabled={props.customerContract.status !== 'draft'}
                      {...field}
                    />
                  )}
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={()=>setShow(false)} disabled={isSubmittingApprovers}>キャンセル</Button>
          <Button variant='primary' onClick={submitModalFormHandler} disabled={props.customerContract.status !== 'draft' || !isApproversFormDirty || isSubmittingApprovers}>
            保存{isSubmittingApprovers && '中'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}