import React, {useEffect} from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { MobileStepperLayoutPropsType } from './types'

export const MobileStepperLayout: React.FC<MobileStepperLayoutPropsType> = (props) => {
  const setCurrentStepByHash = () => {
    if (props.setCurrentStep) {
      const currentStep = location.hash.replace('#', '')
      if (!currentStep) return
      props.setCurrentStep(prevSteps => prevSteps.map(step=>({...step, isCurrent: step.value === currentStep})))
      props.afterStepChange && props.afterStepChange(currentStep)
    }
  }

  useEffect(() => {
    window.addEventListener('hashchange', setCurrentStepByHash)
    if (!props.skipResetInitialStep) {
      setCurrentStepByHash()
    }
  }, [])

  return (
    <Row className='ui-layout w-100 m-0 min-vh-100 bg-aqua'>
      <Col className='mw-540px mx-auto small-sm'>
        {props.children}
      </Col>
    </Row>
  )
}