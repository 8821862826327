import React from 'react'
import { createRoot } from 'react-dom/client'
import { useForm, Controller } from 'react-hook-form'
import { Col, Row, Form, Button } from 'react-bootstrap'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import { Fetch } from '@/utils/Fetch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs'

import { CustomerContractsSearchFormPropsType, SearchFormType } from '../types'

import { toCamelCaseObjectFromSnakeCase } from '@/utils/toCamelCaseObjectFromSnakeCase'
import { statusOptions } from '@/components/features/CustomerContract/types'
import { CustomerType } from '@/components/features/Customer/types'

type Options = {
  value: string
  label: string
}[]

const CustomerContractsSearchForm: React.FC<CustomerContractsSearchFormPropsType> = (props) => {
  const folderOptions = props.folders.map(folder=>({value: folder.id, label: folder.name}))
  const approverOptions = props.approvers.map(approver=>({value: approver.id, label: `${approver.lastName}${approver.firstName}`}))
  const {
    register,
    control,
    handleSubmit
  } = useForm<SearchFormType>({defaultValues: {
    ...props.searchParams,
    selectedFolders: folderOptions.filter(opt=>props.searchParams.folderIds?.includes(opt.value)),
    selectedApprover: approverOptions.find(opt=>opt.value === props.searchParams.approverId),
    selectedCustomer: props.searchParams.customer.id ? {
      value: props.searchParams.customer.id,
      label: `${props.searchParams.customer.lastName}${props.searchParams.customer.firstName}`
    } : undefined,
    statusOption: statusOptions.find(opt=>opt.value === props.searchParams.status)
  }})
  const onSubmit = handleSubmit((data: SearchFormType) => {
    window.location.href = `${props.folderId ? '/folders/' + props.folderId : ''}/customer_contracts?name=${data.name || ''}&status=${data.statusOption?.value || ''}&customer_id=${data.selectedCustomer?.value || ''}&approver_id=${data.selectedApprover?.value || ''}&created_at_start=${dayjs(data.createdAtStart || null).format('YYYY-MM-DD')}&created_at_end=${dayjs(data.createdAtEnd || null).format('YYYY-MM-DD')}`
  })
  const loadCustomerOptions = (
    inputValue: string,
    callback: (options: Options) => void
  ) => {
    let customerOptions: Options = []
    ;(async () => {
      const res = await Fetch(`/api/v1/customers?search_text=${inputValue}`, 'GET', {})
      if (res.ok) {
        const json = await res.json()
        const customers = toCamelCaseObjectFromSnakeCase<{customers: CustomerType[]}>(json).customers
        customerOptions = customers.map(customer=>({value: customer.id, label: `${customer.lastName}${customer.firstName}`}))
      }
      callback(customerOptions)
    })()
  }

  return (
    <Col xs={12}>
      <Form className='px-0 d-flex' onSubmit={onSubmit}>
        <Row className='gy-2'>
          <Col xs={12} xl={6} className='px-1'>
            <Form.Label htmlFor='searchName'>
              タイトル
            </Form.Label>
            <Form.Control
              {...register('name')}
              id='searchName'
            />
          </Col>
          <Col xs={12} sm={4} xl={3} className='px-1'>
            <Form.Label htmlFor='selectedCustomer'>
              クライアント
            </Form.Label>
            <Controller
              control={control}
              name='selectedCustomer'
              render={({ field }) => (
                <AsyncSelect
                  cacheOptions
                  loadOptions={loadCustomerOptions}
                  noOptionsMessage={() => '入力してクライアント検索'}
                  isClearable
                  placeholder='選択してください'
                  {...field}
                />
              )}
            />
          </Col>
          <Col xs={12} sm={4} xl={3} className='px-1'>
            <Form.Label htmlFor='selectedApprover'>
              承認ユーザー
            </Form.Label>
            <Controller
              control={control}
              name='selectedApprover'
              render={({ field }) => (
                <Select
                  options={approverOptions}
                  isClearable
                  placeholder='選択してください'
                  {...field}
                />
              )}
            />
          </Col>
          <Col xs={12} sm={4} xl={3} className='px-1'>
            <Form.Label htmlFor='statusOption'>
              ステータス
            </Form.Label>
            <Controller
              control={control}
              name='statusOption'
              render={({ field }) => (
                <Select
                  options={statusOptions}
                  isClearable
                  placeholder='選択してください'
                  {...field}
                />
              )}
            />
          </Col>
          <Col xs={12} sm={9} xl={6} className='px-1'>
            <Form.Label htmlFor='createdAtStart'>
              作成日
            </Form.Label>
            <div className='text-nowrap'>
              <Form.Control
                {...register('createdAtStart')}
                id='createdAtStart'
                type='date'
                className='mw-160px d-inline-block me-1'
              />
              〜
              <Form.Control
                {...register('createdAtEnd')}
                id='createdAtEnd'
                type='date'
                className='mw-160px d-inline-block ms-1'
              />
            </div>
          </Col>
          <Col xs={12} sm={3} className='d-flex justify-content-sm-end align-items-sm-end px-1'>
            <Button onClick={onSubmit} type='submit' variant='outline-primary'><FontAwesomeIcon icon={faMagnifyingGlass}/> 検索する</Button>
          </Col>
        </Row>
      </Form>
    </Col>
  )
}

document.addEventListener('DOMContentLoaded', () => {
  const node = document.getElementById('customer-contracts-search-form')
  if (node) {
    const data = node.dataset
    if(!data.props) return
    const props = toCamelCaseObjectFromSnakeCase<CustomerContractsSearchFormPropsType>(JSON.parse(data.props))
    const formedCustomerContractNew = createRoot(node)
    formedCustomerContractNew.render(
      <CustomerContractsSearchForm
        {...props}
      />
    )
  }
})
