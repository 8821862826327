type Method = 'GET' | 'POST' | 'DELETE' | 'PUT' | 'PATCH'

export const Fetch = async (url: string, method: Method, bodyObject: object, params = {}) => {
  const csrfToken = (document
    .getElementsByName('csrf-token')
    .item(0) as HTMLMetaElement).content
  const headers = new Headers()
  headers.set('Accept', 'application/json')
  headers.set('X-CSRF-Token', csrfToken)
  if (!(bodyObject instanceof FormData)) {
    headers.set('Content-Type', 'application/json')
  }
  const body = bodyObject instanceof FormData ? bodyObject : JSON.stringify(bodyObject)

  const res = await fetch(url, {
    method: method,
    headers,
    body: method !== 'GET' ? body : null,
    ...params
  })

  return res
}