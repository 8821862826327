import React, { useCallback } from 'react'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import { QuestionItemsFormPropsType, commonAnswerTypeOptions, fieldAnswerTypeOptions } from '../../types'

import { QuestionItemForm } from '../QuestionItemForm'

export const QuestionItemsForm: React.FC<QuestionItemsFormPropsType> = (props) => {
  const addQuestionItems = useCallback(()=>{
    const itemKey = props.appendQuestionItem(props.forInputField ? fieldAnswerTypeOptions[0] : commonAnswerTypeOptions[0])
    if (props.forInputField && props.currentDocumentPage?.id) {
      props.appendItemOutputLocation({
        itemKey,
        documentId: props.currentDocumentPage.id,
        page: props.currentDocumentPage.page,
        xCoord: 0,
        yCoord:0
      })
    }
  }, [props.appendQuestionItem, props.forInputField, props.questionItems, props.currentDocumentPage])

  return (
    <>
      {props.questionItems.map((item, index) => (
        (
          (props.forInputField && ['signature_field', 'signature_date_field', 'text_field', 'textarea_field', 'checkbox_field', 'stamp_field'].includes(item.answerType)) ||
          (!props.forInputField && !['signature_field', 'signature_date_field', 'text_field', 'textarea_field', 'checkbox_field', 'stamp_field'].includes(item.answerType))
        ) ?
        <QuestionItemForm
          {...props}
          key={item.key}
          index={index}
          questionItem={item}
          removeQuestionItem={()=>props.removeQuestionItem(index)}
          switchQuestionItems={props.switchQuestionItems && ((arrow: 'up' | 'down')=>props.switchQuestionItems && props.switchQuestionItems(index, arrow))}
        />
        :
        <React.Fragment key={item.id}></React.Fragment>
      ))}
      <Card className="my-2 border-0">
        <Button
          variant='outline-primary'
          onClick={addQuestionItems}
          disabled={props.disabled}
        >
          <h6 className="my-0 text-center"><FontAwesomeIcon icon={faPlus}/> {props.forInputField ? '記入欄を追加' : '質問項目を追加'}</h6>
        </Button>
      </Card>
    </>
  )
}