import React, { useState, useEffect, useCallback } from 'react'
import { Controller } from 'react-hook-form'
import { Fetch } from '@/utils/Fetch'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Table from 'react-bootstrap/Table'
import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile, faFileExport } from '@fortawesome/free-solid-svg-icons'

import { DocumentsTabDisplay } from '@/components/features/Document/components'
import { DocumentType } from '@/components/features/Document/types'
import { OutputLocationType } from '@/components/features/ItemOutputLocation/types'
import { isFieldTypeQuestion } from '@/components/features/ContractSetting/hooks/useContractSettingForm'
import { toCamelCaseObjectFromSnakeCase } from '@/utils/toCamelCaseObjectFromSnakeCase'

import { BasicInfoPropsType } from '../../types'
import { convertInputTypeToAnswerType } from '../../hooks/useCustomerContractForm'

type Options = {
  value: string
  label: string
}[]

type Folders = {
  id: string
  name: string
}[]

export const BasicInfo: React.FC<BasicInfoPropsType> = (props) => {
  const isNewContract = !props.customerContract?.id || !props.refreshCustomerContract
  const documents: DocumentType[] = (isNewContract ? props.contractSetting?.draftDocuments : props.customerContract?.documents.map(doc => ({...doc, disableToDelete: !!doc.draftDocumentId}))) || []
  const [folderOptions, setFolderOptions] = useState<Options>([])
  const [showModal, setShowModal] = useState(false)

  const deleteDocument = useCallback((documentId: string) => {
    if (!props.customerContract?.id || !props.refreshCustomerContract) return
    const refreshCustomerContract = props.refreshCustomerContract
    const customerContractId = props.customerContract.id
    ;(async () => {
      const res = await Fetch(`/api/v1/customer_contracts/${customerContractId}/documents/${documentId}`, 'DELETE', {})
      if (res.ok) {
        refreshCustomerContract()
      }
    })()
  }, [props.refreshCustomerContract, props.customerContract])

  const questionItemOutputLocations: OutputLocationType[] = props.contractSetting?.questionItems.flatMap(item => {
    if (isFieldTypeQuestion(item.answerType) && !props.customerContract?.id) return []
    const answerItem = props.customerContract?.answerItems[item.id]
    const itemLocations = item.outputLocations?.map(loc => ({
      ...loc,
      itemKey: item.id,
      label: item.label,
      sublabel: item.sublabel,
      unit: item.unit,
      customerOrder: item.customerOrder,
      answerType: item.answerType,
      value: answerItem?.value,
      subvalue: answerItem?.subvalue,
      width: item?.width,
      height: item?.height
    })) || []
    return itemLocations
  }) || []
  const fieldOutputLocations: OutputLocationType[] = props.customerContract?.contractInvitees.flatMap(invitee =>
    invitee.documentFields.flatMap(field =>
      field.fieldLocations.map(loc=>({
        ...loc,
        itemKey: field.key,
        label: `${invitee.inviteeLastName}${invitee.inviteeFirstName}`,
        documentId: field.documentId,
        answerType: convertInputTypeToAnswerType(field.inputType)
      }))
    )
  ) || []
  const outputLocations = [...questionItemOutputLocations, ...fieldOutputLocations]

  const getFolderOptions = async() => {
    const res = await Fetch('/api/v1/folders', 'GET', {})
    const json = await res.json()
    const folders = toCamelCaseObjectFromSnakeCase<{folders: Folders}>(json).folders
    setFolderOptions(folders.map(folder => ({value: folder.id, label: folder.name})))
  }

  useEffect(() => {
    getFolderOptions()
  }, [])

  return (
    <>
    <header className="row align-items-center justify-content-end sticky-top bg-white z-2000">
      <Col className='text-end'>
        {
          !props.customerContract?.id ?
          <Button
            variant="primary"
            onClick={props.submitCustomerContract}
            disabled={props.isSubmittingCustomerContract}
          >
            作成{props.isSubmittingCustomerContract && '中'}
          </Button>
          :
          <Button
            variant="primary"
            onClick={props.submitCustomerContract}
            disabled={!props.isCustomerContractFormDirty || props.isSubmittingCustomerContract}
          >
            保存{!props.isCustomerContractFormDirty  && '済み'}{props.isSubmittingCustomerContract && '中'}
          </Button>
        }
      </Col>
    </header>
    <Row>
      <Col>
        <Form onSubmit={props.submitCustomerContract}>
          <Row className='gy-3 mt-1'>
            <Col
              xs={12}
            >
              <Form.Label htmlFor='contractTemplateName'>
                契約書名
              </Form.Label>
              <Form.Control
                {...props.customerContractRegister('name', {required: true})}
                id='contractTemplateName'
                type="text"
                required
                placeholder='〇〇契約書'
                disabled={!!props.customerContract?.id && props.customerContract.status !== 'draft'}
              />
            </Col>
            {
              !props.folderId && folderOptions.length > 0 &&
              <>
                <Col
                  xs={12}
                >
                  <Form.Label htmlFor='folderOption'>
                    カテゴリー
                  </Form.Label>
                  <Controller
                    control={props.customerContractControl}
                    name={'folderOption'}
                    render={({ field }) => (
                      <Select
                        className='z-1500'
                        options={folderOptions}
                        {...field}
                      />
                    )}
                    rules={{required: true}}
                  />
                </Col>
              </>
            }
            <Col
              xs={12}
            >
              <Form.Label htmlFor='additionalDocuments'>
                {(props.customerContract && props.customerContract.documents.length > 0) ? '書類を追加' : '書類をアップロード'}
              </Form.Label>
              <Form.Control
                {...props.customerContractRegister('additionalDocuments')}
                id='additionalDocuments'
                type="file"
                accept='application/pdf'
                disabled={!!props.customerContract?.id && props.customerContract.status !== 'draft'}
              />
            </Col>
          </Row>
        </Form>
        <DocumentsTabDisplay
          documents={documents}
          deleteDocument={(isNewContract || props.customerContract?.status !== 'draft') ? undefined : deleteDocument}
          outputLocations={outputLocations}
          tabsClassName='pt-5'
        />
        {
          props.contractSettingOptions && props.contractSettingOptions.length > 0 &&
          <>
            <div
              className='border my-3 px-5 py-4 rounded'
            >
              <h6>
                <FontAwesomeIcon icon={faFile}/> テンプレートから選択
              </h6>
              <div className='display-1 text-center'>
                <FontAwesomeIcon icon={faFileExport}/>
              </div>
              <div className='mt-3 d-grid'>
                <Button
                  variant='primary'
                  onClick={()=>setShowModal(true)}
                >
                  テンプレートから選択
                </Button>
              </div>
            </div>
            <Modal
              show={showModal}
              onHide={()=>setShowModal(false)}
              size='xl'
              fullscreen='lg-down'
            >
              <Modal.Header closeButton>
                テンプレートから選択
              </Modal.Header>
              <Modal.Body>
                <Table>
                  <thead>
                    <tr>
                      {
                        folderOptions.length > 0 &&
                        <th>カテゴリー</th>
                      }
                      <th>テンプレート名</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      props.contractSettingOptions.map(opt=>
                        <tr key={opt.id}>
                          {
                            folderOptions.length > 0 &&
                            <td className='align-middle'>{opt.folder?.name || '無し(管理者のみ閲覧可)'}</td>
                          }
                          <td className='align-middle'>{opt.name}</td>
                          <td className='align-middle'>
                            <div className='d-grid'>
                              <Button
                                variant='outline-primary'
                                onClick={()=>{
                                  window.location.href = `/customer_contracts/new?contract_setting_id=${opt.id}`
                                }}
                                size='sm'
                              >
                                選択
                              </Button>
                            </div>
                          </td>
                        </tr>
                      )
                    }
                  </tbody>
                </Table>
              </Modal.Body>
              <Modal.Footer>
                <Button variant='secondary' onClick={()=>setShowModal(false)}>キャンセル</Button>
              </Modal.Footer>
            </Modal>
          </>
        }
      </Col>
    </Row>
    </>
  )
}