// Entry point for the build script in your package.json

import '@fortawesome/fontawesome-free/js/solid.js'
import '@fortawesome/fontawesome-free/js/fontawesome.js'
import 'jquery'
import '@popperjs/core'
import 'bootstrap'
import 'stylesheets/application/index.scss'
import '@/pages/member/ContractSettings/edit'
import '@/pages/member/ContractSettings/new'
import '@/pages/member/CustomerContracts/new'
import '@/pages/member/CustomerContracts/edit'
import '@/pages/member/CustomerContracts/components/CustomerContractSearchForm'
