import { useState, useLayoutEffect, RefObject } from 'react'
import useResizeObserver from '@react-hook/resize-observer'

type DOMRectProperty = Omit<DOMRect, 'toJSON'>

export const useSize = <T extends HTMLElement>(
  elementRef: RefObject<T>
) => {
  const [size, setSize] = useState<DOMRectProperty>({
    width: 0,
    height: 0,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    x: 0,
    y: 0
  })

  const handleResize = () => {
    const clientRect = elementRef.current?.getBoundingClientRect()
    if (clientRect) {
      setSize(clientRect)
    }
  }

  useLayoutEffect(()=>{
    window.addEventListener('resize', handleResize)
    handleResize()
  }, [elementRef.current])

  useResizeObserver(elementRef.current, () => {
    handleResize()
  })

  return size
}