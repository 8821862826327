export type ContractSettingsEditPropsType = {
  id: string
  fileUploadStatus: 'in_work' | 'done'
  questionItemsSettingStatus: 'in_work' | 'done'
  approverSettingStatus: 'in_work' | 'done'
  previewStatus: 'in_work' | 'done'
}

export const stepOptions = [
  {label: '書類アップロード', value: 'files_upload'},
  {label: '質問項目設定', value: 'question_items_setting'},
  {label: '記入欄設定', value: 'input_fields_setting'},
  {label: 'プレビュー', value: 'previews'}
] as const
