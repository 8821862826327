import React, { useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import { useWindowSize } from 'react-use'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'

import { QuestionItemsSettingPropsType } from '../../types'
import { OutputLocationType } from '@/components/features/ItemOutputLocation/types'
import { useContractSettingForm } from '../../hooks/useContractSettingForm'

import { QuestionItemsForm } from '@/components/features/QuestionItem/components/QuestionItemsForm'
import { DocumentsTabDisplay } from '@/components/features/Document/components'
import { QuestionFormPreview } from '@/components/features/QuestionItem/components/QuestionFormPreview'

export const QuestionItemsSetting: React.FC<QuestionItemsSettingPropsType> = (props) => {
  const [show, setShow] = useState(false)
  const { width } = useWindowSize()
  const {
    contractSettingRegister: customerInviteeNumRegister,
    submitContractSetting: submitCustomerInviteeNum,
    isContractSettingFormDirty: isCustomerInviteeNumFormDirty,
    isSubmittingContractSetting: isSubmittingCustomerInviteeNum,
  } = useContractSettingForm(props.contractSetting.id, {customerInviteeNum: props.contractSetting.customerInviteeNum})
  const submitModalFormHandler = async () => {
    await submitCustomerInviteeNum()
    props.refreshContractSetting && props.refreshContractSetting()
    setShow(false)
  }
  const [currentDocumentPage, setCurrentDocumentPage] = useState({id: '', page: 1})
  const outputLocations: OutputLocationType[] = props.itemOutputLocations.map(loc => {
    const questionItem = props.contractSetting.questionItems.find(item => item.key === loc.itemKey)
    return ({
      ...loc,
      label: questionItem?.label || '',
      sublabel: questionItem?.sublabel,
      unit: questionItem?.unit,
      answerType: questionItem?.answerType || 'text',
      width: questionItem?.width,
      height: questionItem?.height
    })
  })
  const setLocationsValue = (fieldIndex: number, xCoord: number, yCoord: number) => {
    props.setContractSettingValue(`itemOutputLocations.${fieldIndex}.xCoord`, xCoord, { shouldDirty: true })
    props.setContractSettingValue(`itemOutputLocations.${fieldIndex}.yCoord`, yCoord, { shouldDirty: true })
  }

  return (
    <>
      <header className="row align-items-center justify-content-end position-absolute w-100 z-100">
        <Col className='text-end'>
          <Button
            variant="primary"
            onClick={props.submitContractSetting}
            disabled={!props.isContractSettingFormDirty || props.isSubmittingContractSetting || props.contractSetting?.status !== 'draft'}
          >
            保存{!props.isContractSettingFormDirty && '済み'}{props.isSubmittingContractSetting && '中'}
          </Button>
          {
            !!props.contractSetting?.id &&
            <Button
              variant="danger"
              className="ms-3"
              onClick={props.deleteContractSetting}
            >
              削除
            </Button>
          }
        </Col>
      </header>
      <Row className='h-100 pt-5 position-relative'>
        <div className='pb-4 w-100 position-absolute start-0 bottom-0 z-100 bg-white-to-b'></div>
        <Col md={12} lg={5} xl={4} xxl={3} className={`overflow-scroll h-100 ${width >= 992 ? 'pb-50vh' : 'pb-3'}`}>
          <h5 className='sticky-top bg-white px-2 mb-4 bg-b-4-white'>質問項目</h5>
          <Card className="bg-pink p-2 mb-2 border-0">
            <Row>
              <Col className="text-nowrap py-1 pe-0">クライアント数 : {props.contractSetting.customerInviteeNum}人</Col>
              <Col className="text-nowrap text-end">
                <Button
                  variant='outline-primary'
                  size='sm'
                  onClick={()=>setShow(true)}
                  disabled={props.contractSetting?.status !== 'draft'}
                >
                  <FontAwesomeIcon icon={faPenToSquare} className='ms-1'/>人数変更
                </Button>
              </Col>
            </Row>
          </Card>
          <div className='my-2 px-2'><div className='w-100 border-top'></div></div>
          <QuestionItemsForm
            {...props}
            questionItems={props.contractSetting.questionItems}
            draftDocuments={props.contractSetting.draftDocuments}
            currentDocumentPage={currentDocumentPage}
            customerInviteeNum={props.contractSetting.customerInviteeNum}
            disabled={props.contractSetting?.status !== 'draft'}
          />
          {
            width < 992 &&
            <DocumentsTabDisplay
              documents={props.contractSetting.draftDocuments || []}
              setCurrentDocumentPage={setCurrentDocumentPage}
              outputLocations={outputLocations}
              setLocationsValue={props.contractSetting?.status !== 'draft' ? undefined : setLocationsValue}
              removeLocation={props.contractSetting?.status !== 'draft' ? undefined : props.removeItemOutputLocation}
              childrenTitle='回答フォーム'
            >
              <QuestionFormPreview
                questionItems={props.contractSetting.questionItems}
                contractSettingName={props.contractSetting.name}
              />
            </DocumentsTabDisplay>
          }
        </Col>
        {
          width >= 992 &&
          <Col lg={7} xl={8} xxl={9} className='bg-white overflow-scroll h-100 pb-4'>
            <DocumentsTabDisplay
              documents={props.contractSetting.draftDocuments || []}
              setCurrentDocumentPage={setCurrentDocumentPage}
              outputLocations={outputLocations}
              setLocationsValue={props.contractSetting?.status !== 'draft' ? undefined : setLocationsValue}
              removeLocation={props.contractSetting?.status !== 'draft' ? undefined : props.removeItemOutputLocation}
              childrenTitle='回答フォーム'
            >
              <QuestionFormPreview
                questionItems={props.contractSetting.questionItems}
                contractSettingName={props.contractSetting.name}
              />
            </DocumentsTabDisplay>
          </Col>
        }
      </Row>
      <Modal show={show} onHide={()=>setShow(false)}>
        <Modal.Header closeButton>
          クライアントの人数設定
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={submitModalFormHandler}>
            <Row className='gy-2'>
              <Form.Label column xs={3} htmlFor='customerInviteeNum'>
                クライアント数
              </Form.Label>
              <Col
                xs={9}
              >
                <InputGroup>
                  <Form.Control
                    {...customerInviteeNumRegister('customerInviteeNum', {required: true})}
                    id='customerInviteeNum'
                    type="number"
                    required
                    disabled={props.contractSetting?.status !== 'draft'}
                  />
                  <InputGroup.Text>人</InputGroup.Text>
                </InputGroup>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={()=>setShow(false)} disabled={isSubmittingCustomerInviteeNum}>キャンセル</Button>
          <Button
            variant='primary'
            onClick={submitModalFormHandler}
            disabled={!isCustomerInviteeNumFormDirty || isSubmittingCustomerInviteeNum || props.contractSetting?.status !== 'draft'}
          >
            保存{isSubmittingCustomerInviteeNum && '中'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}