import React, { useState, useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { Fetch } from '@/utils/Fetch'

import { CustomerContractsNewPropsType, stepOptions } from './types'

import { toCamelCaseObjectFromSnakeCase } from '@/utils/toCamelCaseObjectFromSnakeCase'
import { TopStepperLayout } from '@/components/layouts'
import { StepType } from '@/components/layouts/components/Sidebar/types'
import { BasicInfo, CustomerForms } from '@/components/features/CustomerContract/components'
import { ContractSettingOptions } from '@/components/features/CustomerContract/types'
import { useCustomerContractForm } from '@/components/features/CustomerContract/hooks/useCustomerContractForm'
import { isCustomerTypeQuestion } from '@/components/features/ContractSetting/hooks/useContractSettingForm'

const CustomerContractNew: React.FC<CustomerContractsNewPropsType> = (props) => {
  const {
    customerContract,
    customerContractRegister,
    customerContractControl,
    submitCustomerContract,
    isCustomerContractFormDirty,
    isSubmittingCustomerContract,
    refreshCustomerContract,
    setCustomerContractValue,
    resetCustomerContractForm,
    customerContractErrors
  } = useCustomerContractForm('', props.contractSetting, props.folderId)
  const [steps, setSteps] = useState<StepType[]>(
    stepOptions.map((opt, i) => ({...opt, isCurrent: i === 0}))
  )
  const [contractSettingOptions, setContractSettingOptions] = useState<ContractSettingOptions>([])

  const isDone = (key: string) => {
    switch (key) {
      case 'basic_info':
        return !!props.contractSetting
      default:
        return false
    }
  }
  const isDisabled = (key: string) => {
    switch (key) {
      case 'basic_info':
        return false
      case 'customer_forms':
        return !props.contractSetting?.questionItems.some(questionItem=>isCustomerTypeQuestion(questionItem.answerType))
      default:
        return true
    }
  }

  const getContractSettingOptions = async() => {
    const res = await Fetch('/api/v1/contract_settings', 'GET', {})
    const json = await res.json()
    const contractSettings = toCamelCaseObjectFromSnakeCase<{contractSettings: ContractSettingOptions}>(json).contractSettings
    setContractSettingOptions(
      !props.folderId ? contractSettings : contractSettings.filter(set => set.folder?.id === props.folderId)
    )
  }

  useEffect(() => {
    setCustomerContractValue('name', props.contractSetting?.name || '')
    if (!props.contractSetting?.id) getContractSettingOptions()
  }, [])

  return (
    <TopStepperLayout
      title='契約書'
      steps={steps.map(step=>({
        ...step,
        isDone: isDone(step.value),
        isDisabled: isDisabled(step.value),
        isSkipped: !props.contractSetting?.id && step.value === 'answer_item_forms'
      }))}
      setCurrentStep={setSteps}
      afterStepChange={()=>resetCustomerContractForm()}
    >
      {(() => {
        const currentStep = steps.find(step => step.isCurrent)
        switch (currentStep?.value) {
          case 'basic_info':
            return (
              <>
                <BasicInfo
                  {...props}
                  customerContract={customerContract}
                  customerContractRegister={customerContractRegister}
                  customerContractControl={customerContractControl}
                  submitCustomerContract={submitCustomerContract}
                  isCustomerContractFormDirty={isCustomerContractFormDirty}
                  isSubmittingCustomerContract={isSubmittingCustomerContract}
                  refreshCustomerContract={refreshCustomerContract}
                  contractSettingOptions={contractSettingOptions}
                />
              </>
            )
          case 'customer_forms':
            return (
              <CustomerForms
                contractSetting={props.contractSetting}
                customerContract={customerContract}
                customerContractRegister={customerContractRegister}
                submitCustomerContract={submitCustomerContract}
                setCustomerContractValue={setCustomerContractValue}
                isCustomerContractFormDirty={isCustomerContractFormDirty}
                isSubmittingCustomerContract={isSubmittingCustomerContract}
                customerContractErrors={customerContractErrors}
              />
            )
          default:
            return <></>
        }
      })()}
    </TopStepperLayout>
  )
}

document.addEventListener('DOMContentLoaded', () => {
  const node = document.getElementById('customer-contracts-new')
  if (node) {
    const data = node.dataset
    if(!data.props) return
    const props = toCamelCaseObjectFromSnakeCase<CustomerContractsNewPropsType>(JSON.parse(data.props))
    const formedCustomerContractNew = createRoot(node)
    formedCustomerContractNew.render(
      <CustomerContractNew
        {...props}
      />
    )
  }
})
