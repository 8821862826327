import React from 'react'
import { createRoot } from 'react-dom/client'

import { TopStepperLayout } from '@/components/layouts'

import { stepOptions } from './types'

import { useContractSettingForm } from '@/components/features/ContractSetting/hooks/useContractSettingForm'
import { FilesUpload } from '@/components/features/ContractSetting/components'

const ContractSettingsNew: React.FC<Record<string, never>> = () => {
  const steps = stepOptions.map(opt => ({...opt, isDone: false, isCurrent: opt.value === 'files_upload'}))
  const {
    contractSettingRegister,
    contractSettingControl,
    submitContractSetting,
    refreshContractSetting,
    isContractSettingFormDirty,
    isSubmittingContractSetting
  } = useContractSettingForm()

  return (
    <TopStepperLayout
      title='テンプレート'
      steps={steps}
    >
      <FilesUpload
        contractSettingRegister={contractSettingRegister}
        contractSettingControl={contractSettingControl}
        submitContractSetting={submitContractSetting}
        refreshContractSetting={refreshContractSetting}
        isContractSettingFormDirty={isContractSettingFormDirty}
        isSubmittingContractSetting={isSubmittingContractSetting}
      />
    </TopStepperLayout>
  )
}

document.addEventListener('DOMContentLoaded', () => {
  const node = document.getElementById('contract-settings-new')
  if (node) {
    const contractSettingsNew = createRoot(node)
    contractSettingsNew.render(
      <ContractSettingsNew/>
    )
  }
})
