import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'
import { useForm } from 'react-hook-form'
import { Fetch } from '@/utils/Fetch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faChevronRight, faTrashCan, faShare } from '@fortawesome/free-solid-svg-icons'

import { CustomerSearchModalType, CustomerSearchFormType, CustomerType } from '../../types'
import { toCamelCaseObjectFromSnakeCase } from '@/utils/toCamelCaseObjectFromSnakeCase'

export const CustomerSearchModal: React.FC<CustomerSearchModalType> = (props) => {
  const [selectedCustomers, setSelectedCustomers] = useState<CustomerType[]>([])
  const [customerOptions, setCustomerOptions] = useState<CustomerType[]>([])
  const {
    register,
    handleSubmit,
    formState: {isSubmitting}
  } = useForm<CustomerSearchFormType>()
  const searchCustomers = handleSubmit(async (data: CustomerSearchFormType) => {
    const path = `/api/v1/customers?search_text=${data.searchText}`
    const res = await Fetch(path, 'GET', {})
    if (res.ok) {
      const json = await res.json()
      const customers = toCamelCaseObjectFromSnakeCase<{customers: CustomerType[]}>(json).customers
      setCustomerOptions(customers)
    } else {
      setCustomerOptions([])
    }
  })

  const addSelectedCustomer = (customer: CustomerType) => {
    setSelectedCustomers(prevState=> props.maxNum !== undefined && prevState.length >= props.maxNum ? [...prevState] : [...prevState, customer])
  }

  const removeSelectedCustomer = (index: number) => {
    setSelectedCustomers(prevState=> {
      const customers = [...prevState]
      customers.splice(index, 1)
      return customers
    })
  }

  useEffect(() => {
    if (props.show) {
      setSelectedCustomers(props.currentCustomers)
    } else {
      setSelectedCustomers([])
    }
  }, [props.show])

  return (
    <Modal
      show={props.show}
      onHide={()=>props.setShow(false)}
      size="lg"
      centered
      scrollable
      contentClassName='h-100 mh-100'
    >
      <Modal.Header closeButton>
        {props.title || 'クライアント検索'}
      </Modal.Header>
      <Modal.Body className='p-0'>
        <Row className='mx-0 h-100 mh-100'>
          <Col xs={6} className='bg-secondary overflow-scroll h-100'>
            <Form onSubmit={searchCustomers} className='sticky-top'>
              <Row className='gy-1 py-2 bg-light border-bottom'>
                <Col xs={12}>
                  <InputGroup>
                    <Form.Control
                      {...register('searchText')}
                      placeholder='挙式日(yyyy/mm/dd)/姓名/カナ/メールアドレス'
                    />
                    <Button variant='outline-primary' type='submit' onClick={searchCustomers} disabled={isSubmitting}>
                      <FontAwesomeIcon icon={faMagnifyingGlass}/> 検索{isSubmitting && '中'}
                    </Button>
                  </InputGroup>
                </Col>
              </Row>
            </Form>
            <Row className='py-1'>
              {
                customerOptions.map(opt=>
                  <Col
                    xs={12}
                    key={opt.id}
                    className={`py-2 btn ${props.maxNum !== undefined && selectedCustomers.length >= props.maxNum ? 'disabled' : ''}`}
                    onClick={()=>addSelectedCustomer(opt)}
                  >
                    <Row className='text-start'>
                      <Col xs={10}>
                        <div className='small'>{opt.weddingDate?.replaceAll('-', '/')}</div>
                        <div className='text-truncate'>
                          {opt.lastName} {opt.firstName} <span className='small'>{!!opt.lastNameKana || !!opt.firstNameKana ? `${opt.lastNameKana} ${opt.firstNameKana}` : ''}</span>
                        </div>
                        <div className='small text-truncate'>{opt.email}</div>
                      </Col>
                      <Col xs={2} className='text-center my-auto'><FontAwesomeIcon icon={faChevronRight}/></Col>
                    </Row>
                  </Col>
                )
              }
            </Row>
          </Col>
          <Col xs={6} className='overflow-scroll h-100'>
            <Row className=''>
              {
                selectedCustomers.map((customer, index)=>
                  <Col xs={12} key={index} className='py-2 border-bottom'>
                    <Row className='gy-1'>
                      <Col xs={6}>
                        クライアント{index + 1}
                        {
                          !!customer.weddingDate &&
                          <span className='small ms-1 text-nowrap'>挙式日: {customer.weddingDate.replaceAll('-', '/')}</span>
                        }
                      </Col>
                      <Col xs={6} className='text-end'>
                        {
                          !!customer.id &&
                          <a
                            href={`/customers/${customer.id}/edit`}
                            target="_blank" rel="noopener noreferrer"
                            className='btn btn-outline-primary btn-sm'
                          >
                            修正
                          </a>
                        }
                        <Button
                          variant='outline-dark mx-1'
                          size='sm'
                          className='border-0'
                          onClick={() => removeSelectedCustomer(index)}
                        >
                          <FontAwesomeIcon icon={faTrashCan}/>
                        </Button>
                      </Col>
                      <Col xs={12}>
                        <div className='small'>メールアドレス</div>
                        <div className='text-truncate bg-light px-1'>{customer.email || '　'}</div>
                      </Col>
                      <Col xs={6}>
                        <div className='small'>姓</div>
                        <div className='text-truncate bg-light px-1'>{customer.lastName || '　'}</div>
                      </Col>
                      <Col xs={6}>
                        <div className='small'>名</div>
                        <div className='text-truncate bg-light px-1'>{customer.firstName || '　'}</div>
                      </Col>
                      <Col xs={6}>
                        <div className='small'>セイ</div>
                        <div className='text-truncate bg-light px-1'>{customer.lastNameKana || '　'}</div>
                      </Col>
                      <Col xs={6}>
                        <div className='small'>メイ</div>
                        <div className='text-truncate bg-light px-1'>{customer.firstNameKana || '　'}</div>
                      </Col>
                    </Row>
                  </Col>
                )
              }
            </Row>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={()=>props.setShow(false)} disabled={isSubmitting}>キャンセル</Button>
        <Button variant='primary' onClick={()=>props.submitSelectedCustomers(selectedCustomers)} disabled={isSubmitting}>
          <FontAwesomeIcon icon={faShare}/> 反映
        </Button>
      </Modal.Footer>
    </Modal>
  )
}