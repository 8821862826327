import { useState, useEffect } from 'react'
import { useForm, useFieldArray, useFormState, DefaultValues } from 'react-hook-form'
import { Fetch } from '@/utils/Fetch'
import { ContractSettingFormType, ContractSettingType, ContractSettingSubmitBodyType, statusOptions } from '../types'
import { customerAnswerTypeOptions, commonAnswerTypeOptions, fieldAnswerTypeOptions, answerTypeOptions } from '../../QuestionItem/types'

const customerAnswerTypes: string[] = customerAnswerTypeOptions.map(opt=>opt.value)
export const isCustomerTypeQuestion = (answerType: typeof answerTypeOptions[number]['value']) => customerAnswerTypes.includes(answerType)
const fieldAnswerTypes: string[] = fieldAnswerTypeOptions.map(opt=>opt.value)
export const isFieldTypeQuestion = (answerType: typeof answerTypeOptions[number]['value']) => fieldAnswerTypes.includes(answerType)
const commonAnswerTypes: string[] = commonAnswerTypeOptions.map(opt=>opt.value)
export const isCommonTypeQuestion = (answerType: typeof answerTypeOptions[number]['value']) => commonAnswerTypes.includes(answerType)

export const useContractSettingForm = (
  id = '',
  defaultContractSettingValues?: DefaultValues<ContractSettingFormType>
) => {
  const [draftDocumentsState, setDraftDocuments] = useState<ContractSettingType['draftDocuments']>([])
  const [approverSettingsState, setApproverSettings] = useState<ContractSettingType['approverSettings']>([])
  const {
    register: contractSettingRegister,
    control: contractSettingControl,
    handleSubmit,
    watch,
    formState: {
      isDirty: isContractSettingFormDirty,
      isSubmitting: isSubmittingContractSetting
    },
    setValue: setContractSettingValue,
    reset: resetContractSetting
  } = useForm<ContractSettingFormType>({defaultValues: defaultContractSettingValues || {
    name: '',
    statusOption: {value: 'draft', label: '下書き'},
    customerInviteeNum: 1,
    additionalDraftDocuments: [],
    defaultApprovers: [],
    questionItems: [],
    itemOutputLocations: []
  }})
  const contractSetting = convertFormToData(watch(), id, draftDocumentsState, approverSettingsState)
  const { dirtyFields } = useFormState({control: contractSettingControl})

  const submitContractSetting = handleSubmit(async (data: ContractSettingFormType) => {
    const path = `/api/v1/contract_settings/${id}`
    const method = id ? 'PATCH' : 'POST'
    const body: ContractSettingSubmitBodyType = data.additionalDraftDocuments?.length > 0 ? new FormData() : {contract_setting: {}}
    if (body instanceof FormData) {
      Array.from(data.additionalDraftDocuments).forEach(doc => {
        body.append('contract_setting[draft_documents][]', doc)
      })
      if (dirtyFields.name) body.append('contract_setting[name]', data.name)
      if (
        typeof dirtyFields.defaultApprovers === 'object' ? dirtyFields.defaultApprovers?.some(approver=>approver.value) : dirtyFields.defaultApprovers // Selectだと〇〇Optionのdirty stateに直接booleanが入るため
      ) data.defaultApprovers.forEach(approver=>{
        body.append('contract_setting[approver_setting_forms][]', approver.value)
      })
      if (typeof dirtyFields.folderOption === 'object' ? dirtyFields.folderOption?.value : dirtyFields.folderOption) body.append('contract_setting[folder_id]', data.folderOption?.value || '') // Selectだと〇〇Optionのdirty stateに直接booleanが入るため
    } else {
      if (dirtyFields.name) body.contract_setting.name = data.name
      if (typeof dirtyFields.folderOption === 'object' ? dirtyFields.folderOption?.value : dirtyFields.folderOption) body.contract_setting.folder_id = data.folderOption?.value // Selectだと〇〇Optionのdirty stateに直接booleanが入るため
      if (typeof dirtyFields.statusOption === 'object' ? dirtyFields.statusOption?.value : dirtyFields.statusOption) body.contract_setting.status = data.statusOption.value // Selectだと〇〇Optionのdirty stateに直接booleanが入るため
      if (dirtyFields.customerInviteeNum) body.contract_setting.customer_invitee_num = data.customerInviteeNum
      if (
        typeof dirtyFields.defaultApprovers === 'object' ? dirtyFields.defaultApprovers?.some(approver=>approver.value) : dirtyFields.defaultApprovers
      ) body.contract_setting.approver_setting_forms = data.defaultApprovers.map(approver => approver.value)
      if (
        dirtyFields.questionItems?.some(item =>
          (typeof item.answerTypeOption === 'object' ? item.answerTypeOption?.value : item.answerTypeOption) || // Selectだと〇〇Optionのdirty stateに直接booleanが入るため
          (typeof item.answererOption === 'object' ? item.answererOption?.value : item.answererOption) || // Selectだと〇〇Optionのdirty stateに直接booleanが入るため
          item.isRequired ||
          item.label ||
          (typeof item.selectItems === 'object' ? item.selectItems?.some(selectItem=>selectItem.value) : item.selectItems) || // Selectだと〇〇Optionのdirty stateに直接booleanが入るため
          item.sublabel ||
          item.unit ||
          item.width ||
          item.height
        ) ||
        dirtyFields.itemOutputLocations?.some(loc =>
          loc.documentId ||
          loc.page ||
          loc.xCoord ||
          loc.yCoord
        )
      ) body.contract_setting.question_item_forms = data.questionItems.map((item, index) => ({
        id: item.id,
        label: item.label,
        sublabel: item.sublabel,
        answer_type: item.answerTypeOption.value,
        unit: item.unit,
        select_items_text: item.selectItems?.map(selectItem=>selectItem.value).join(','),
        order: index,
        customer_order: (typeof item.answererOption?.value === 'number' ? item.answererOption.value : null),
        approver_setting_id: (typeof item.answererOption?.value === 'string' ? item.answererOption.value : null),
        is_required: item.isRequired,
        width: item.width,
        height: item.height,
        item_output_location_forms: dirtyFields.itemOutputLocations?.some(loc =>
          loc.documentId ||
          loc.page ||
          loc.xCoord ||
          loc.yCoord
        ) ? data.itemOutputLocations.filter(loc=> loc.itemKey === item.key).map(loc => ({
          draft_document_id: loc.documentId,
          page: loc.page,
          x_coord: loc.xCoord,
          y_coord: loc.yCoord
        })) : undefined
      }))
    }
    const res = await Fetch(path, method, body)
    if (res.ok) {
      if (id) {
        refreshContractSetting()
      } else {
        const json = await res.json()
        window.location.href = `/contract_settings/${json.id}/edit`
      }
    } else {
      const json = await res.json()
      const errorMessage = json.errors?.join('\n')
      alert(errorMessage || '保存に失敗しました')
    }
  })

  const { fields: questionItemFields, append: plainAppendQuestionItem, remove: plainRemoveQuestionItem, swap: swapQuestionItems } = useFieldArray({
    name: 'questionItems',
    keyName: 'key',
    control: contractSettingControl
  })
  const questionItems = questionItemFields.map((field, index) => {
    return {
      ...field,
      ...watch().questionItems[index]
    }
  })
  const appendQuestionItem = (answerTypeOption: typeof answerTypeOptions[number] = answerTypeOptions[0]) => {
    const newItemKey = new Date().getTime().toString()
    plainAppendQuestionItem({
      key: newItemKey,
      id: '',
      label: '',
      answerTypeOption: answerTypeOption,
      isRequired: true,
      width: isFieldTypeQuestion(answerTypeOption.value) ? 120 : 180,
      height: 24
    })
    return newItemKey
  }
  const removeQuestionItem = (index?: number | number[] | undefined) => {
    const removedItemKeys: (string | number)[] = []
    if(typeof index === 'number') {
      removedItemKeys.push(questionItems[index].key)
    } else if (typeof index === 'object') {
      removedItemKeys.push(...index.map(itemIndex => questionItems[itemIndex].key))
    }
    plainRemoveQuestionItem(index)
    const linkedLocIndex: number[] = itemOutputLocations.flatMap(
      (loc, i) => removedItemKeys.includes(loc.itemKey) ? i : []
    )
    removeItemOutputLocation(linkedLocIndex)
  }
  const switchQuestionItems = (index: number, arrow: 'up' | 'down') => {
    swapQuestionItems(index, arrow === 'up' ? index - 1 : index + 1)
  }

  const { fields: itemOutputLocationsFields, append: appendItemOutputLocation, remove: removeItemOutputLocation } = useFieldArray({
    name: 'itemOutputLocations',
    control: contractSettingControl
  })
  const itemOutputLocations = itemOutputLocationsFields.map((field, index) => {
    return {
      ...field,
      ...watch().itemOutputLocations[index]
    }
  })

  const refreshContractSetting = () => {
    if (!id) console.error(`id: '${id}'; id is not nullable to refresh Contract Setting`)
    ;(async () => {
      const res = await Fetch(`/api/v1/contract_settings/${id}`, 'GET', {})
      const data: ContractSettingType = await res.json()
      const forms = convertDataToForm(data)
      resetContractSetting(forms)
      setDraftDocuments(data.draftDocuments)
      setApproverSettings(data.approverSettings)
    })()
  }

  const deleteContractSetting = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    if (!id) console.error(`id: '${id}'; id is not nullable to delete Contract Setting`)
    ;(async () => {
      if (window.confirm(
        '⚠️⚠️テンプレートを削除します⚠️⚠️\nこのテンプレートを使った申込ページは非公開となりますがよろしいですか？'
      )) {
        const res = await Fetch(`/api/v1/contract_settings/${id}`, 'PATCH', {contract_setting: {status: 'inactive'}})
        if (res.ok) {
          alert(`テンプレート「${contractSetting.name}」が削除されました`)
          window.location.href = '/contract_settings'
        } else {
          const json = await res.json()
          const errorMessage = json.errors?.join('\n')
          alert(errorMessage || 'テンプレートの削除に失敗しました')
          refreshContractSetting()
        }
      }
    })()
  }

  useEffect(() => {
    if (id && !defaultContractSettingValues) refreshContractSetting()
  }, [id])

  useEffect(() => {
    if (defaultContractSettingValues) resetContractSetting(defaultContractSettingValues)
  }, [
    defaultContractSettingValues?.customerInviteeNum // クライアント数変更モーダルのため
  ])

  return {
    contractSetting,
    contractSettingRegister,
    contractSettingControl,
    submitContractSetting,
    refreshContractSetting,
    setContractSettingValue,
    isContractSettingFormDirty,
    isSubmittingContractSetting,
    appendQuestionItem,
    removeQuestionItem,
    swapQuestionItems,
    switchQuestionItems,
    itemOutputLocations,
    appendItemOutputLocation,
    removeItemOutputLocation,
    resetContractSetting,
    deleteContractSetting
  }
}

const convertDataToForm = (data: ContractSettingType) => {
  const defaultApproversForm: ContractSettingFormType['defaultApprovers'] = data.approverSettings.map(approver => ({value: approver.userId, label: `${approver.lastName}${approver.firstName}`}))
  const orderedQuestionItems = data.questionItems.sort((a, b) => {
    if (isFieldTypeQuestion(a.answerType) && !isFieldTypeQuestion(b.answerType)) {
      return 1
    } else if (!isFieldTypeQuestion(a.answerType) && isFieldTypeQuestion(b.answerType)) {
      return -1
    }
    if (a.order >= b.order) {
      return 1
    } else {
      return -1
    }
  }).map(item => ({...item, key: item.id}))
  const questionItemsForm: ContractSettingFormType['questionItems'] = orderedQuestionItems.map(item => {
    const answerOption = (() => {
      if (typeof item.customerOrder === 'number') {
        return {value: item.customerOrder, label: `クライアント${item.customerOrder + 1}`}
      } else if (typeof item.approverSettingId === 'string') {
        return {value: item.approverSettingId, label: item.approverName || ''}
      }
    })()
    return {
      ...item,
      answerTypeOption: answerTypeOptions.find(opt => opt.value === item.answerType) || answerTypeOptions[0],
      answererOption: answerOption,
      selectItems: item.selectItemsText?.split(',').map(itemText => ({value: itemText, label: itemText}))
    }
  })
  const itemOutputLocationsForm: ContractSettingFormType['itemOutputLocations'] = orderedQuestionItems.flatMap(item => {
    const itemLocations = item.outputLocations?.map(loc => ({
      itemKey: item.key,
      ...loc
    })) || []
    return itemLocations
  })
  const contractSettingForm: ContractSettingFormType = {
    ...data,
    folderOption: data.folder && {
      value: data.folder.id,
      label: data.folder.name
    },
    statusOption: statusOptions.find(opt=>opt.value === data.status) || statusOptions[0],
    defaultApprovers: defaultApproversForm,
    additionalDraftDocuments: [],
    questionItems: questionItemsForm,
    itemOutputLocations: itemOutputLocationsForm
  }
  return contractSettingForm
}

const convertFormToData = (
  form: ContractSettingFormType,
  id = '',
  draftDocuments: ContractSettingType['draftDocuments'] = [],
  approverSettings: ContractSettingType['approverSettings'] = []
) => {
  const questionItemsData: ContractSettingType['questionItems'] = form.questionItems?.map((item, index) => ({
    ...item,
    answerType: item.answerTypeOption.value,
    selectItemsText: item.selectItems?.map(selectItem=>selectItem.value).join(','),
    order: index,
    customerOrder: (typeof item.answererOption?.value === 'number' ? item.answererOption.value : undefined),
    approverSettingId: (typeof item.answererOption?.value === 'string' ? item.answererOption.value : undefined),
    approverName: (typeof item.answererOption?.value === 'string' ? item.answererOption.label : undefined),
    outputLocations: form.itemOutputLocations.filter(loc => loc.itemKey === item.key).map(loc => ({
      documentId: loc.documentId,
      page: loc.page,
      xCoord: loc.xCoord,
      yCoord: loc.yCoord
    }))
  }))
  const approverSettingsData: ContractSettingType['approverSettings'] = approverSettings.length > 0 ? approverSettings : form.defaultApprovers?.map(approver => ({
    id: '',
    userId: approver.value,
    lastName: approver.label,
    firstName: ''
  })) || []
  const contractSettingData: ContractSettingType = {
    ...form,
    id: id,
    folder: form.folderOption && {
      id: form.folderOption.value,
      name: form.folderOption.label
    },
    status: form.statusOption?.value || 'draft',
    draftDocuments: draftDocuments,
    approverSettings: approverSettingsData,
    questionItems: questionItemsData
  }
  return contractSettingData
}
