import React, { useState } from 'react'
import { createRoot } from 'react-dom/client'

import { TopStepperLayout } from '@/components/layouts'
import { StepType } from '@/components/layouts/components/Sidebar/types'

import { ContractSettingsEditPropsType, stepOptions } from './types'

import { useContractSettingForm, isFieldTypeQuestion } from '@/components/features/ContractSetting/hooks/useContractSettingForm'
import { toCamelCaseObjectFromSnakeCase } from '@/utils/toCamelCaseObjectFromSnakeCase'
import { FilesUpload, QuestionItemsSetting, InputFieldsSetting, Previews } from '@/components/features/ContractSetting/components'

const ContractSettingsEdit: React.FC<ContractSettingsEditPropsType> = (props) => {
  const {
    contractSetting,
    contractSettingRegister,
    contractSettingControl,
    submitContractSetting,
    refreshContractSetting,
    isContractSettingFormDirty,
    isSubmittingContractSetting,
    appendQuestionItem,
    removeQuestionItem,
    switchQuestionItems,
    itemOutputLocations,
    appendItemOutputLocation,
    removeItemOutputLocation,
    setContractSettingValue,
    resetContractSetting,
    deleteContractSetting
  } = useContractSettingForm(props.id)
  const [steps, setSteps] = useState<StepType[]>(
    stepOptions.map((opt, i) => ({...opt, isCurrent: i === 0}))
  )
  const isDone = (key: string) => {
    const currentStep = steps.find(step=>step.isCurrent)
    if (key === currentStep?.value && isContractSettingFormDirty) return false
    switch (key) {
      case 'files_upload':
        return !!contractSetting.name && contractSetting.draftDocuments.length > 0 && contractSetting.approverSettings.length > 0
      case 'question_items_setting':
        return contractSetting.questionItems.length > 0 && !!contractSetting.customerInviteeNum
      case 'input_fields_setting':
        return contractSetting.questionItems.some(item => isFieldTypeQuestion(item.answerType) && item.isRequired && (item.outputLocations?.length || 0) > 0)
      case 'previews':
        return contractSetting.status === 'active'
      default:
        return false
    }
  }
  const isDisabled = (key: string) => {
    switch (key) {
      case 'question_items_setting':
        return !isDone('question_items_setting') && !isDone('files_upload')
      case 'input_fields_setting':
        return !isDone('input_fields_setting') && (!isDone('files_upload') || !isDone('question_items_setting'))
      case 'previews':
        return !isDone('previews') && (!isDone('files_upload') || !isDone('question_items_setting') || !isDone('input_fields_setting'))
      default:
        return false
    }
  }

  return (
    <TopStepperLayout
      title='テンプレート'
      steps={steps.map(step=>({
        ...step,
        isDone: isDone(step.value),
        isDisabled: isDisabled(step.value)
      }))}
      setCurrentStep={setSteps}
      afterStepChange={()=>resetContractSetting()}
    >
      {(() => {
        const currentStep = steps.find(step => step.isCurrent)
        switch (currentStep?.value) {
          case 'files_upload':
            return (
              <FilesUpload
                contractSetting={contractSetting}
                contractSettingRegister={contractSettingRegister}
                contractSettingControl={contractSettingControl}
                submitContractSetting={submitContractSetting}
                refreshContractSetting={refreshContractSetting}
                isContractSettingFormDirty={isContractSettingFormDirty}
                isSubmittingContractSetting={isSubmittingContractSetting}
                deleteContractSetting={deleteContractSetting}
              />
            )
          case 'question_items_setting':
            return (
              <QuestionItemsSetting
                contractSetting={contractSetting}
                contractSettingRegister={contractSettingRegister}
                contractSettingControl={contractSettingControl}
                submitContractSetting={submitContractSetting}
                appendQuestionItem={appendQuestionItem}
                removeQuestionItem={removeQuestionItem}
                switchQuestionItems={switchQuestionItems}
                appendItemOutputLocation={appendItemOutputLocation}
                removeItemOutputLocation={removeItemOutputLocation}
                isContractSettingFormDirty={isContractSettingFormDirty}
                isSubmittingContractSetting={isSubmittingContractSetting}
                itemOutputLocations={itemOutputLocations}
                setContractSettingValue={setContractSettingValue}
                refreshContractSetting={refreshContractSetting}
                deleteContractSetting={deleteContractSetting}
              />
            )
          case 'input_fields_setting':
            return (
              <InputFieldsSetting
                contractSetting={contractSetting}
                contractSettingRegister={contractSettingRegister}
                contractSettingControl={contractSettingControl}
                submitContractSetting={submitContractSetting}
                appendQuestionItem={appendQuestionItem}
                removeQuestionItem={removeQuestionItem}
                appendItemOutputLocation={appendItemOutputLocation}
                removeItemOutputLocation={removeItemOutputLocation}
                isContractSettingFormDirty={isContractSettingFormDirty}
                isSubmittingContractSetting={isSubmittingContractSetting}
                itemOutputLocations={itemOutputLocations}
                setContractSettingValue={setContractSettingValue}
                deleteContractSetting={deleteContractSetting}
              />
            )
          case 'previews':
            return (
              <Previews
                contractSetting={contractSetting}
                contractSettingControl={contractSettingControl}
                submitContractSetting={submitContractSetting}
                isContractSettingFormDirty={isContractSettingFormDirty}
                isSubmittingContractSetting={isSubmittingContractSetting}
                deleteContractSetting={deleteContractSetting}
              />
            )
          default:
            return <></>
        }
      })()}
    </TopStepperLayout>
  )
}

document.addEventListener('DOMContentLoaded', () => {
  const node = document.getElementById('contract-settings-edit')
  if (node) {
    const data = node.dataset
    if(!data.props) return
    const props = toCamelCaseObjectFromSnakeCase<ContractSettingsEditPropsType>(JSON.parse(data.props))
    const contractSettingsEdit = createRoot(node)
    contractSettingsEdit.render(
      <ContractSettingsEdit
        {...props}
      />
    )
  }
})
