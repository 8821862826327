import React, { useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { useWindowSize } from 'react-use'

import { InputFieldsSettingPropsType } from '../../types'
import { OutputLocationType } from '@/components/features/ItemOutputLocation/types'

import { DocumentsTabDisplay } from '@/components/features/Document/components'
import { QuestionItemsForm } from '../../../QuestionItem/components/QuestionItemsForm'

export const InputFieldsSetting: React.FC<InputFieldsSettingPropsType> = (props) => {
  const { width } = useWindowSize()
  const [currentDocumentPage, setCurrentDocumentPage] = useState({id: '', page: 1})
  const outputLocations: OutputLocationType[] = props.itemOutputLocations.map(loc => {
    const questionItem = props.contractSetting.questionItems.find(item => item.key === loc.itemKey)
    return ({
      ...loc,
      label: questionItem?.label || '',
      sublabel: questionItem?.sublabel,
      unit: questionItem?.unit,
      answerType: questionItem?.answerType || 'text',
      width: questionItem?.width,
      height: questionItem?.height
    })
  })
  const setLocationsValue = (fieldIndex: number, xCoord: number, yCoord: number) => {
    props.setContractSettingValue(`itemOutputLocations.${fieldIndex}.xCoord`, xCoord, { shouldDirty: true })
    props.setContractSettingValue(`itemOutputLocations.${fieldIndex}.yCoord`, yCoord, { shouldDirty: true })
  }

  return (
    <>
      <header className="row align-items-center justify-content-end position-absolute w-100 z-100">
        <Col className='text-end'>
          <Button
            variant="primary"
            onClick={props.submitContractSetting}
            disabled={!props.isContractSettingFormDirty || props.isSubmittingContractSetting || props.contractSetting?.status !== 'draft'}
          >
            保存{!props.isContractSettingFormDirty && '済み'}{props.isSubmittingContractSetting && '中'}
          </Button>
          {
            !!props.contractSetting?.id &&
            <Button
              variant="danger"
              className="ms-3"
              onClick={props.deleteContractSetting}
            >
              削除
            </Button>
          }
        </Col>
      </header>
      <Row className='h-100 pt-5 position-relative'>
        <div className='pb-4 w-100 position-absolute start-0 bottom-0 z-100 bg-white-to-b'></div>
        <Col md={12} lg={5} xl={4} xxl={3} className={`overflow-scroll h-100 ${width >= 992 ? 'pb-50vh' : 'pb-3'}`}>
          <h5 className='sticky-top bg-white px-2 mb-4 bg-b-4-white'>記入項目</h5>
          <QuestionItemsForm
            {...props}
            questionItems={props.contractSetting.questionItems}
            draftDocuments={props.contractSetting.draftDocuments}
            currentDocumentPage={currentDocumentPage}
            customerInviteeNum={props.contractSetting.customerInviteeNum}
            approverSettings={props.contractSetting.approverSettings}
            forInputField
            disabled={props.contractSetting?.status !== 'draft'}
          />
          {
            width < 992 &&
            <DocumentsTabDisplay
              documents={props.contractSetting.draftDocuments || []}
              setCurrentDocumentPage={setCurrentDocumentPage}
              outputLocations={outputLocations}
              setLocationsValue={props.contractSetting?.status !== 'draft' ? undefined : setLocationsValue}
              removeLocation={props.contractSetting?.status !== 'draft' ? undefined : props.removeItemOutputLocation}
            />
          }
        </Col>
        {
          width >= 992 &&
          <Col lg={7} xl={8} xxl={9} className='bg-white overflow-scroll h-100 pb-4'>
            <DocumentsTabDisplay
              documents={props.contractSetting.draftDocuments || []}
              setCurrentDocumentPage={setCurrentDocumentPage}
              outputLocations={outputLocations}
              setLocationsValue={props.contractSetting?.status !== 'draft' ? undefined : setLocationsValue}
              removeLocation={props.contractSetting?.status !== 'draft' ? undefined : props.removeItemOutputLocation}
            />
          </Col>
        }
      </Row>
    </>
  )
}