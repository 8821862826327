import React, { useRef, useState, useEffect } from 'react'
import { useSize } from '@/utils/hooks/useSize'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan } from '@fortawesome/free-solid-svg-icons'

import { DocumentPagination, PdfView } from '..'
import { ItemOutputLocationsStage } from '@/components/features/ItemOutputLocation/components/ItemOutputLocationsStage'

import { DocumentsTabDisplayPropsType, PageViewType, DocumentPageType } from '../../types'

export const DocumentsTabDisplay: React.FC<DocumentsTabDisplayPropsType> = (props) => {
  const viewerRef = useRef(null)
  const viewerProperty = useSize(viewerRef)

  const [currentDocumentId, setCurrentDocumentId] = useState<string | null>()
  const [allDocumentPages, setAllDocumentPages] = useState<DocumentPageType[]>([])

  const currentDocumentPage = allDocumentPages.find(docPage => docPage.id === currentDocumentId) || allDocumentPages[0]
  const pageScale = (viewerProperty?.width || 0) / (currentDocumentPage?.pageSizes[currentDocumentPage.currentPage - 1]?.width || 1)

  const addDocumentPage = (documentPage: DocumentPageType) => {
    setAllDocumentPages(prevAllDocs => [...prevAllDocs, documentPage])
    if (!props.children && !currentDocumentId) {
      setCurrentDocumentId(documentPage.id)
    }
  }
  const onDocumentLoadSuccess = async (documentId: string, pdf: any) => { // eslint-disable-line
    if (pdf.numPages) {
      const pdfPageSizes: PageViewType[] = []
      for (let i = 1; i <= pdf.numPages; i++) {
        const page = await pdf.getPage(i)
        pdfPageSizes.push({
          width: page.view[2] - page.view[0],
          height: page.view[3] - page.view[1]
        })
      }
      addDocumentPage({id: documentId, currentPage: 1, totalPage: pdf.numPages, pageSizes: pdfPageSizes})
    }
  }
  const setDocumentCurrentPage = (documentId: string, currentPage: number) => {
    const newAllDocs = [...allDocumentPages]
    const index = allDocumentPages.findIndex(doc=> doc.id === documentId)
    const prevDoc = allDocumentPages[index]
    newAllDocs[index] = {...prevDoc, currentPage: currentPage}
    setAllDocumentPages(newAllDocs)
    if (props.setCurrentDocumentPage) {
      props.setCurrentDocumentPage({id: documentId, page: currentPage})
    }
  }

  const maxWidthClasses = () => {
    switch (props.documents.length + (props.children ? 1 : 0)) {
      case 0:
      case 1:
        return ''
      case 2:
        return 'mw-2nd-items'
      case 3:
        return 'mw-3rd-items'
      case 4:
        return 'mw-4th-items'
      case 5:
        return 'mw-5th-items'
      case 6:
        return 'mw-6th-items'
      case 7:
        return 'mw-7th-items'
      default:
        return 'mw-8th-items'
    }
  }

  useEffect(() => {
    if (props.setCurrentDocumentPage) {
      const documentPage = allDocumentPages.find(docPage => docPage.id === currentDocumentId)
      props.setCurrentDocumentPage({id: currentDocumentId || '', page: documentPage?.currentPage || 1})
    }
  }, [currentDocumentId, allDocumentPages])

  useEffect(() => {
    if (props.documents[0]?.id) {
      setCurrentDocumentId(props.documents[0].id)
    }
  }, [props.documents[0]])

  return (
    <>
      <Tabs
        className={`${maxWidthClasses()} ${props.tabsClassName || ''} sticky-top bg-white`}
        justify
        defaultActiveKey={props.children ? 'children' : props.documents[0]?.id}
        activeKey={currentDocumentId || (props.children && 'children') || props.documents[0]?.id}
        onSelect={k => setCurrentDocumentId(k)}
      >
        {
          props.children &&
          <Tab
            tabClassName={`text-truncate d-block border-bottom ${!currentDocumentId || currentDocumentId === 'children' ? '' : 'border-light'}`}
            key='children'
            eventKey='children'
            title={props.childrenTitle || 'Home'}
          >
            {props.children}
          </Tab>
        }
        {
          props.documents.map(doc => (
            <Tab
              tabClassName={`text-truncate d-block border-bottom ${currentDocumentId === doc.id ? '' : 'border-light'}`}
              key={doc.id}
              eventKey={doc.id}
              title={doc.name}
            >
            </Tab>
          ))
        }
      </Tabs>
      {
        props.documents.map((doc, i) => {
          const documentPage = allDocumentPages.find(docPage => docPage.id === doc.id)
          return (
            <div className={`pdf-container mx-auto ${(doc.id === currentDocumentId || (!props.children && !currentDocumentId && i === 0)) ? '' : 'd-none'}`} key={doc.id}>
              <Row>
                {
                  !!props.deleteDocument && !doc.disableToDelete &&
                  <Col>
                    <Button
                      variant="outline-danger"
                      size='sm'
                      className='my-2'
                      onClick={() => {
                        if (props.deleteDocument && window.confirm(`アップロードした書類「${doc.name}」を削除してよろしいですか？`)) {
                          props.deleteDocument(doc.id)
                        }
                      }}
                    >
                      <FontAwesomeIcon icon={faTrashCan}/>
                    </Button>
                  </Col>
                }
                <Col>
                  <DocumentPagination
                    currentPage={documentPage?.currentPage || 1}
                    setCurrentPage={value => setDocumentCurrentPage(doc.id, value)}
                    totalPages={documentPage?.totalPage || 1}
                  />
                </Col>
              </Row>
              <PdfView
                pdfUrl={doc.url}
                currentPage={documentPage?.currentPage || 1}
                onDocumentLoadSuccess={pdf => onDocumentLoadSuccess(doc.id, pdf)}
                viewerRef={(doc.id === currentDocumentId || (!currentDocumentId && i === 0)) ? viewerRef : undefined}
              />
            </div>
          )
        })
      }
      {
        currentDocumentId !== 'children' &&
        (
          (props.outputLocations && props.outputLocations.length > 0) ||
          (props.fixedOutputLocations && props.fixedOutputLocations.length > 0)
        ) &&
        <div className='pdf-container mx-auto'>
          <div className='position-relative'>
            <ItemOutputLocationsStage
              {...props}
              outputLocations={props.outputLocations || []}
              currentDocumentPage={currentDocumentPage}
              pageScale={pageScale}
              width={viewerProperty?.width || 0}
              height={viewerProperty?.height || 0}
            />
          </div>
        </div>
      }
    </>
  )
}