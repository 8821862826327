import React from 'react'
import Row from 'react-bootstrap/Row'

import { SideStepper } from '../components/Sidebar/SideStepper'

import { SideStepperLayoutPropsType } from './types'

export const SideStepperLayout: React.FC<SideStepperLayoutPropsType> = (props) => {
  return (
    <Row className='ui-layout w-100 m-0'>
      <SideStepper
        {...props}
      />
      {props.children}
    </Row>
  )
}