import React, { useState, useEffect } from 'react'
import { Fetch } from '@/utils/Fetch'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { Controller } from 'react-hook-form'
import Select from 'react-select'

import { toCamelCaseObjectFromSnakeCase } from '@/utils/toCamelCaseObjectFromSnakeCase'

import { DocumentsTabDisplay } from '@/components/features/Document/components'

import { FilesUploadPropsType } from '../../types'

type Options = {
  value: string
  label: string
}[]

type Users = {
  id: string
  lastName: string
  firstName: string
}[]

type Folders = {
  id: string
  name: string
}[]

export const FilesUpload: React.FC<FilesUploadPropsType> = (props) => {
  const [userOptions, setUserOptions] = useState<Options>([])
  const [folderOptions, setFolderOptions] = useState<Options>([])

  const deleteDraftDocument = (documentId: string) => {
    if (!props.refreshContractSetting) return
    const refreshContractSetting = props.refreshContractSetting
    ;(async () => {
      const res = await Fetch(`/api/v1/contract_settings/${props.contractSetting?.id || ''}/draft_documents/${documentId}`, 'DELETE', {})
      if (res.ok) {
        refreshContractSetting()
      }
    })()
  }

  const getUserOptions = async() => {
    const res = await Fetch('/api/v1/users', 'GET', {})
    const json = await res.json()
    const users = toCamelCaseObjectFromSnakeCase<{users: Users}>(json).users
    setUserOptions(users.map(user => ({value: user.id, label: `${user.lastName}${user.firstName}`})))
  }
  const getFolderOptions = async() => {
    const res = await Fetch('/api/v1/folders', 'GET', {})
    const json = await res.json()
    const folders = toCamelCaseObjectFromSnakeCase<{folders: Folders}>(json).folders
    setFolderOptions(folders.map(folder => ({value: folder.id, label: folder.name})))
  }

  useEffect(() => {
    getUserOptions()
    getFolderOptions()
  }, [])

  return (
    <>
    <header className="row align-items-center justify-content-end sticky-top bg-white z-2500">
      <Col className='text-end'>
        <Button
          variant="primary"
          onClick={props.submitContractSetting}
          disabled={!props.isContractSettingFormDirty || props.isSubmittingContractSetting || (props.contractSetting && props.contractSetting.status !== 'draft')}
        >
          保存{!props.isContractSettingFormDirty && '済み'}{props.isSubmittingContractSetting && '中'}
        </Button>
        {
          !!props.contractSetting?.id &&
          <Button
            variant="danger"
            className="ms-3"
            onClick={props.deleteContractSetting}
          >
            削除
          </Button>
        }
      </Col>
    </header>
    <Row>
      <Col className=''>
        <Form onSubmit={props.submitContractSetting}>
          <Row className='gy-2 mt-3'>
            <Form.Label column lg={2} md={3} htmlFor='contractTemplateName'>
              タイトル
            </Form.Label>
            <Col
              lg={10}
              md={9}
            >
              <Form.Control
                {...props.contractSettingRegister('name', {required: true})}
                id='contractTemplateName'
                type="text"
                required
                placeholder='〇〇契約書'
                disabled={props.contractSetting && props.contractSetting.status !== 'draft'}
              />
            </Col>
            {
              folderOptions.length > 0 &&
              <>
                <Form.Label column lg={2} md={3} htmlFor='folderOption'>
                  カテゴリー
                </Form.Label>
                <Col
                  lg={10}
                  md={9}
                >
                  <Controller
                    control={props.contractSettingControl}
                    name={'folderOption'}
                    render={({ field }) => (
                      <Select
                        className='z-2000'
                        options={folderOptions}
                        isDisabled={props.contractSetting && props.contractSetting.status !== 'draft'}
                        {...field}
                      />
                    )}
                  />
                </Col>
              </>
            }
            <Form.Label column lg={2} md={3} htmlFor='defaultApprovers'>
              承認ユーザー
            </Form.Label>
            <Col
              lg={10}
              md={9}
            >
              <Controller
                control={props.contractSettingControl}
                name={'defaultApprovers'}
                render={({ field }) => (
                  <Select
                    className='z-1500'
                    options={userOptions}
                    isMulti
                    isDisabled={props.contractSetting && props.contractSetting.status !== 'draft'}
                    {...field}
                  />
                )}
              />
            </Col>
            <Form.Label column lg={2} md={3} htmlFor='draftDocuments'>
              契約書PDF
            </Form.Label>
            <Col
              lg={10}
              md={9}
            >
              <Form.Control
                {...props.contractSettingRegister('additionalDraftDocuments')}
                id='draftDocuments'
                type="file"
                accept='application/pdf'
                multiple
                disabled={props.contractSetting && props.contractSetting.status !== 'draft'}
              />
            </Col>
          </Row>
        </Form>
        <DocumentsTabDisplay
          documents={props.contractSetting?.draftDocuments || []}
          deleteDocument={props.contractSetting?.status !== 'draft' ? undefined : deleteDraftDocument}
          tabsClassName='pt-5'
        />
      </Col>
    </Row>
    </>
  )
}