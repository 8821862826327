import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Badge from 'react-bootstrap/Badge'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Select from 'react-select'
import { Controller } from 'react-hook-form'

import { AnswerItemFormsPropsType } from '../../types'
import { isCommonTypeQuestion } from '@/components/features/ContractSetting/hooks/useContractSettingForm'

export const AnswerItemForms: React.FC<AnswerItemFormsPropsType> = (props) => {
  return (
    <>
    <header className="row align-items-center justify-content-between sticky-top bg-white">
      <Col className='h4 mb-0 text-truncate'>{props.customerContract?.name}</Col>
      <Col className='text-end'>
        <Button
          variant="primary"
          onClick={props.submitCustomerContract}
          disabled={!props.isCustomerContractFormDirty || props.isSubmittingCustomerContract}
        >
          保存{!props.isCustomerContractFormDirty && '済み'}{props.isSubmittingCustomerContract && '中'}
        </Button>
      </Col>
    </header>
    <Row>
      <Col className='pb-5'>
        <Form onSubmit={props.submitCustomerContract}>
          <Row className='gy-3 mt-3'>
            {
              props.contractSetting?.questionItems.filter(questionItem=>isCommonTypeQuestion(questionItem.answerType)).map(questionItem => {
                switch (questionItem.answerType){
                  case 'date':
                    return (
                      <Col key={questionItem.id} xs={12}>
                        <Form.Label htmlFor={`answerItems.${questionItem.id}.value`}>
                          <span>{questionItem.label}</span> {questionItem.isRequired && <Badge bg='danger' className='align-text-bottom'>必須</Badge>}
                        </Form.Label>
                        <Form.Control
                          {...props.customerContractRegister(`answerItems.${questionItem.id}.value` as const, {required: questionItem.isRequired})}
                          id={`answerItems.${questionItem.id}.value`}
                          type='date'
                          required={questionItem.isRequired}
                          disabled={props.customerContract?.status !== 'draft'}
                          className='mw-200px'
                        />
                      </Col>
                    )
                  case 'time':
                    return (
                      <Col key={questionItem.id} xs={12}>
                        <Form.Label htmlFor={`answerItems.${questionItem.id}.value`}>
                          <span>{questionItem.label}</span> {questionItem.isRequired && <Badge bg='danger' className='align-text-bottom'>必須</Badge>}
                        </Form.Label>
                        <Form.Control
                          {...props.customerContractRegister(`answerItems.${questionItem.id}.value` as const, {required: questionItem.isRequired})}
                          id={`answerItems.${questionItem.id}.value`}
                          type='time'
                          required={questionItem.isRequired}
                          disabled={props.customerContract?.status !== 'draft'}
                          className='mw-200px'
                        />
                      </Col>
                    )
                  case 'textarea':
                    return (
                      <Col key={questionItem.id} xs={12}>
                        <Form.Label htmlFor={`answerItems.${questionItem.id}.value`}>
                          <span>{questionItem.label}</span> {questionItem.isRequired && <Badge bg='danger' className='align-text-bottom'>必須</Badge>}
                        </Form.Label>
                        <Form.Control
                          {...props.customerContractRegister(`answerItems.${questionItem.id}.value` as const, {required: questionItem.isRequired})}
                          id={`answerItems.${questionItem.id}.value`}
                          as='textarea'
                          type='textarea'
                          required={questionItem.isRequired}
                          disabled={props.customerContract?.status !== 'draft'}
                        />
                      </Col>
                    )
                  case 'number':
                    return (
                      <Col key={questionItem.id} xs={12}>
                        <Form.Label htmlFor={`answerItems.${questionItem.id}.value`}>
                          <span>{questionItem.label}</span> {questionItem.isRequired && <Badge bg='danger' className='align-text-bottom'>必須</Badge>}
                        </Form.Label>
                        <InputGroup className='mw-200px'>
                          <Form.Control
                            {...props.customerContractRegister(`answerItems.${questionItem.id}.value` as const, {required: questionItem.isRequired})}
                            id={`answerItems.${questionItem.id}.value`}
                            type='number'
                            required={questionItem.isRequired}
                            disabled={props.customerContract?.status !== 'draft'}
                          />
                          <InputGroup.Text>{questionItem.unit}</InputGroup.Text>
                        </InputGroup>
                      </Col>
                    )
                  case 'single_select':
                    return (
                      <Col key={questionItem.id} xs={12}>
                        <Form.Label htmlFor={`answerItems.${questionItem.id}.selectedOptions`}>
                          <span>{questionItem.label}</span> {questionItem.isRequired && <Badge bg='danger' className='align-text-bottom'>必須</Badge>}
                        </Form.Label>
                        <Controller
                          control={props.customerContractControl}
                          name={`answerItems.${questionItem.id}.selectedOption`}
                          render={({ field }) => (
                            <Select
                              options={questionItem.selectItemsText?.split(',').map(itemText=>({value: itemText, label: itemText}))}
                              isDisabled={props.customerContract?.status !== 'draft'}
                              {...field}
                            />
                          )}
                          rules={{required: questionItem.isRequired}}
                        />
                      </Col>
                    )
                  case 'multi_select':
                    return (
                      <Col key={questionItem.id} xs={12}>
                        <Form.Label>
                          <span>{questionItem.label}</span> {questionItem.isRequired && <Badge bg='danger' className='align-text-bottom'>必須</Badge>}
                        </Form.Label>
                        <Row>
                          {
                            questionItem.selectItemsText?.split(',').map((itemText, i) =>
                              <Col className='text-nowrap' key={i}>
                                <Form.Check
                                  {...props.customerContractRegister(`answerItems.${questionItem.id}.checkedOptions.${i}.isChecked`)}
                                  inline
                                  type="checkbox"
                                  label={itemText}
                                  disabled={props.customerContract?.status !== 'draft'}
                                />
                              </Col>
                            )
                          }
                        </Row>
                      </Col>
                    )
                  case 'name':
                  case 'name_kana':
                    return (
                      <React.Fragment key={questionItem.id}>
                        <Col xs={6}>
                          <Form.Label htmlFor={`answerItems.${questionItem.id}.value`}>
                            <span>{questionItem.label}</span> {questionItem.isRequired && <Badge bg='danger' className='align-text-bottom'>必須</Badge>}
                          </Form.Label>
                          <Form.Control
                            {...props.customerContractRegister(`answerItems.${questionItem.id}.value` as const, {required: questionItem.isRequired})}
                            id={`answerItems.${questionItem.id}.value`}
                            type='text'
                            required={questionItem.isRequired}
                            disabled={props.customerContract?.status !== 'draft'}
                          />
                        </Col>
                        <Col xs={6}>
                          <Form.Label htmlFor={`answerItems.${questionItem.id}.subvalue`}>
                            {questionItem.sublabel}
                          </Form.Label>
                          <Form.Control
                            {...props.customerContractRegister(`answerItems.${questionItem.id}.subvalue` as const, {required: questionItem.isRequired})}
                            id={`answerItems.${questionItem.id}.subvalue`}
                            type='text'
                            required={questionItem.isRequired}
                            disabled={props.customerContract?.status !== 'draft'}
                          />
                        </Col>
                      </React.Fragment>
                    )
                  default:
                    return (
                      <Col key={questionItem.id} xs={12}>
                        <Form.Label htmlFor={`answerItems.${questionItem.id}.value`}>
                          <span>{questionItem.label}</span> {questionItem.isRequired && <Badge bg='danger' className='align-text-bottom'>必須</Badge>}
                        </Form.Label>
                        <Form.Control
                          {...props.customerContractRegister(`answerItems.${questionItem.id}.value` as const, {required: questionItem.isRequired})}
                          id={`answerItems.${questionItem.id}.value`}
                          type='text'
                          required={questionItem.isRequired}
                          disabled={props.customerContract?.status !== 'draft'}
                        />
                      </Col>
                    )
                }
              })
            }
          </Row>
        </Form>
      </Col>
    </Row>
    </>
  )
}