import { UseFormRegister, Control, UseFieldArrayAppend, UseFieldArrayRemove, UseFormSetValue } from 'react-hook-form'
import { CustomerContractType, CustomerContractFormType } from '../CustomerContract/types'

export type DocumentFieldsFormPropsType = {
  contractInvitees: CustomerContractType['contractInvitees']
  customerContractRegister: UseFormRegister<CustomerContractFormType>
  customerContractControl: Control<CustomerContractFormType>
  appendDocumentField: (documentId: string, inputTypeOption?: (typeof inputTypeOptions)[number], documentPage?: number) => void
  removeDocumentField: UseFieldArrayRemove
  setCustomerContractValue: UseFormSetValue<CustomerContractFormType>
  currentDocumentPage?: {
    id: string
    page: number
  }
  documents?: {
    readonly id: string
    name: string
  }[]
  appendFieldLocation: UseFieldArrayAppend<CustomerContractFormType, 'fieldLocations'>
  documentFields: CustomerContractFormType['documentFields']
  isDisabled?: boolean
  fieldLocations?: { fieldKey: string; }[]
}

export type DocumentFieldFormPropsType = {
  index: number
  documentField: CustomerContractFormType['documentFields'][number]
  customerContractRegister: UseFormRegister<CustomerContractFormType>
  customerContractControl: Control<CustomerContractFormType>
  removeDocumentField?: UseFieldArrayRemove
  setCustomerContractValue: UseFormSetValue<CustomerContractFormType>
  currentDocumentPage?: {
    id: string
    page: number
  }
  documents?: {
    readonly id: string
    name: string
  }[]
  appendFieldLocation?: UseFieldArrayAppend<CustomerContractFormType, 'fieldLocations'>
  contractInviteeOptions: {
    value: string
    label: string
  }[]
  isDisabled?: boolean
  isNoLocation? :boolean
}

export const inputTypeOptions = [
  {value: 'signature', label: '署名'},
  {value: 'signature_date', label: '署名日時'},
  {value: 'text', label: '文字列'},
  {value: 'textarea', label: '文章'},
  {value: 'checkbox', label: 'チェックボックス'},
  {value: 'stamp', label: '印鑑'}
] as const